/* eslint-disable no-unused-vars */
import { getFromStorage } from "helpers";
import { useEffect } from "react";
import { useState } from "react";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import { authService } from "services";

export const PublicRoutes = ({ children }) => {
    const location = useLocation();
    const [profile, setProfile] = useState(() => getFromStorage("ally-vendor"));
    const rda = localStorage.getItem("redirectUrl");
    const hasOnboarded =
        Boolean(profile?.user?.profile?.businessName) &&
        Boolean(profile?.user?.profile?.businessDocs.length > 0);

    const handleStorageChange = () => {
        setProfile(getFromStorage("ally-vendor"));
    };

    useEffect(() => {
        window.addEventListener("storage", handleStorageChange);
        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    if (
        profile?.user?.active &&
        authService.loggedIn() &&
        profile?.user?.signupVerified &&
        hasOnboarded
    ) {
        if (rda) {
            return <Navigate to={`${rda}`} replace />;
        } else {
            return <Navigate to={"/dashboard"} replace />;
        }
    }

    if (
        !profile?.user?.active &&
        authService.loggedIn() &&
        !hasOnboarded &&
        profile?.user?.signupVerified
    ) {
        return <Navigate to={`/onboarding`} replace />;
    }

    return children;
};
