export const authTypes = {
  LOG_OUT: "LOG_OUT",
  REGISTER_VENDOR_REQUEST: "REGISTER_VENDOR_REQUEST",
  REGISTER_VENDOR_SUCCESS: "REGISTER_VENDOR_SUCCESS",
  REGISTER_VENDOR_FAILURE: "REGISTER_VENDOR_FAILURE",
  VERIFY_VENDOR_REQUEST: "VERIFY_VENDOR_REQUEST",
  VERIFY_VENDOR_SUCCESS: "VERIFY_VENDOR_SUCCESS",
  VERIFY_VENDOR_FAILURE: "VERIFY_VENDOR_FAILURE",
  RESEND_VERIFY_CODE_REQUEST: "RESEND_VERIFY_CODE_REQUEST",
  RESEND_VERIFY_CODE_SUCCESS: "RESEND_VERIFY_CODE_SUCCESS",
  RESEND_VERIFY_CODE_FAILURE: "RESEND_VERIFY_CODE_FAILURE",
  CHANGE_SIGNUP_EMAIL_REQUEST: "CHANGE_SIGNUP_EMAIL_REQUEST",
  CHANGE_SIGNUP_EMAIL_SUCCESS: "CHANGE_SIGNUP_EMAIL_SUCCESS",
  CHANGE_SIGNUP_EMAIL_FAILURE: "CHANGE_SIGNUP_EMAIL_FAILURE",
  COMPLETE_ONBOARDING_REQUEST: "COMPLETE_ONBOARDING_REQUEST",
  COMPLETE_ONBOARDING_SUCCESS: "COMPLETE_ONBOARDING_SUCCESS",
  COMPLETE_ONBOARDING_FAILURE: "COMPLETE_ONBOARDING_FAILURE",
  VENDOR_LOGIN_REQUEST: "VENDOR_LOGIN_REQUEST",
  VENDOR_LOGIN_SUCCESS: "VENDOR_LOGIN_SUCCESS",
  VENDOR_LOGIN_FAILURE: "VENDOR_LOGIN_FAILURE",
  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
  FETCH_VENDOR_PROFILE_REQUEST: "FETCH_VENDOR_PROFILE_REQUEST",
  FETCH_VENDOR_PROFILE_SUCCESS: "FETCH_VENDOR_PROFILE_SUCCESS",
  FETCH_VENDOR_PROFILE_FAILURE: "FETCH_VENDOR_PROFILE_FAILURE",
  UPDATE_VENDOR_PROFILE_FAILURE: "UPDATE_VENDOR_PROFILE_FAILURE",
  UPDATE_VENDOR_PROFILE_REQUEST: "UPDATE_VENDOR_PROFILE_REQUEST",
  UPDATE_VENDOR_PROFILE_SUCCESS: "UPDATE_VENDOR_PROFILE_SUCCESS",
};
