import { orderTypes } from "redux/types";


const initialState = {
    fetchingRfqs: false,
    fetchedRfqsSuccess: null,
    fetchedRfqsFailure: null,
    fetchingOrders: false,
    fetchedOrdersSuccess: null,
    fetchedOrdersFailure: null,
    fetchingSingleRfq: false,
    fetchedSingleRfqSuccess: null,
    fetchedSingleRfqFailure: null,
    fetchingSinglePurchaseOrder: false,
    fetchedSinglePurchaseOrderSuccess: null,
    fetchedSinglePurchaseOrderFailure: null,
    fetchingQuotes: false,
    fetchedQuotesSuccess: null,
    fetchedQuotesFailure: null,
    fetchingSingleQuotes: false,
    fetchedSingleQuotesSuccess: null,
    fetchedSingleQuotesFailure: null,
    fetchingSingleQuoteResponses: false,
    fetchedSingleQuoteResponsesSuccess: null,
    fetchedSingleQuoteResponsesFailure: null,
    deleteQuote: false,
    deleteQuoteSuccess: null,
    deleteQuoteFailure: null,
    updatingOrder: false,
    updateOrderSuccess: null,
    updateOrderFailure: null,
    updatingRfq: false,
    updateRfqSuccess: null,
    updateRfqFailure: null,
    createPurchaseOrder: false,
    createPurchaseOrderSuccess: null,
    createPurchaseOrderFailure: null,

    createQuote: false,
    createQuoteSuccess: null,
    createQuoteFailure: null,

    updateQuote: false,
    updateQuoteSuccess: null,
    updateQuoteFailure: null,

    sendQuote: false,
    sendQuoteSuccess: null,
    sendQuoteFailure: null,

    declineRfqs: false,
    declineRfqsSuccess: null,
    declineRfqsFailure: null,

    updatePurchaseOrder: false,
    updatePurchaseOrderSuccess: null,
    updatePurchaseOrderFailure: null,
    fetchingPurchaseOrder: false,
    fetchedPurchaseOrderSuccess: null,
    fetchedPurchaseOrderFailure: null,
    sendPurchaseOrder: false,
    sendPurchaseOrderSuccess: null,
    sendPurchaseOrderFailure: null,
    fetchingApprovedResponses: false,
    fetchedApprovedResponsesSuccess: null,
    fetchedApprovedResponsesFailure: null,

    fetchingVendorNotification: false,
    fetchedVendorNotificationSuccess: null,
    fetchedVendorNotificationFailure: null,

    readOneVendorNotification: false,
    readOneVendorNotificationSuccess: null,
    readOneVendorNotificationFailure: null,
};

export const order = (state = initialState, action) => {
    switch (action.type) {
        case orderTypes.FETCH_RFQS_REQUEST:
            return {
                ...state,
                fetchingRfqs: true,
                fetchedRfqsSuccess: null,
                fetchedRfqsFailure: null,
            };
        case orderTypes.FETCH_RFQS_SUCCESS:
            return {
                ...state,
                fetchingRfqs: false,
                fetchedRfqsSuccess: action.payload,
                fetchedRfqsFailure: null,
            };
        case orderTypes.FETCH_RFQS_FAILURE:
            return {
                ...state,
                fetchingRfqs: false,
                fetchedRfqsSuccess: null,
                fetchedRfqsFailure: action.payload,
            };

        case orderTypes.FETCH_ORDERS_REQUEST:
            return {
                ...state,
                fetchingOrders: true,
                fetchedOrdersSuccess: null,
                fetchedOrdersFailure: null,
            };
        case orderTypes.FETCH_ORDERS_SUCCESS:
            return {
                ...state,
                fetchingOrders: false,
                fetchedOrdersSuccess: action.payload,
                fetchedOrdersFailure: null,
            };
        case orderTypes.FETCH_ORDERS_FAILURE:
            return {
                ...state,
                fetchingOrders: false,
                fetchedOrdersSuccess: null,
                fetchedOrdersFailure: action.payload,
            };

        case orderTypes.FETCH_SINGLE_RFQ_REQUEST:
            return {
                ...state,
                fetchingSingleRfq: true,
                fetchedSingleRfqSuccess: null,
                fetchedSingleRfqFailure: null,
            };
        case orderTypes.FETCH_SINGLE_RFQ_SUCCESS:
            return {
                ...state,
                fetchingSingleRfq: false,
                fetchedSingleRfqSuccess: action.payload,
                fetchedSingleRfqFailure: null,
            };
        case orderTypes.FETCH_SINGLE_RFQ_FAILURE:
            return {
                ...state,
                fetchingSingleRfq: false,
                fetchedSingleRfqSuccess: null,
                fetchedSingleRfqFailure: action.payload,
            };

        case orderTypes.FETCH_SINGLE_PURCHASE_ORDER_REQUEST:
            return {
                ...state,
                fetchingSinglePurchaseOrder: true,
                fetchedSinglePurchaseOrderSuccess: null,
                fetchedSinglePurchaseOrderFailure: null,
            };
        case orderTypes.FETCH_SINGLE_PURCHASE_ORDER_SUCCESS:
            return {
                ...state,
                fetchingSinglePurchaseOrder: false,
                fetchedSinglePurchaseOrderSuccess: action.payload,
                fetchedSinglePurchaseOrderFailure: null,
            };
        case orderTypes.FETCH_SINGLE_PURCHASE_ORDER_FAILURE:
            return {
                ...state,
                fetchingSinglePurchaseOrder: false,
                fetchedSinglePurchaseOrderSuccess: null,
                fetchedSinglePurchaseOrderFailure: action.payload,
            };
        case orderTypes.FETCH_QUOTES_REQUEST:
            return {
                ...state,
                fetchingQuotes: true,
                fetchedQuotesSuccess: null,
                fetchedQuotesFailure: null,
            };
        case orderTypes.FETCH_QUOTES_SUCCESS:
            return {
                ...state,
                fetchingQuotes: false,
                fetchedQuotesSuccess: action.payload,
                fetchedQuotesFailure: null,
            };
        case orderTypes.FETCH_QUOTES_FAILURE:
            return {
                ...state,
                fetchingQuotes: false,
                fetchedQuotesSuccess: null,
                fetchedQuotesFailure: action.payload,
            };
        case orderTypes.FETCH_SINGLE_QUOTES_REQUEST:
            return {
                ...state,
                fetchingSingleQuotes: true,
                fetchedSingleQuotesSuccess: null,
                fetchedSingleQuotesFailure: null,
            };
        case orderTypes.FETCH_SINGLE_QUOTES_SUCCESS:
            return {
                ...state,
                fetchingSingleQuotes: false,
                fetchedSingleQuotesSuccess: action.payload,
                fetchedSingleQuotesFailure: null,
            };
        case orderTypes.FETCH_SINGLE_QUOTES_FAILURE:
            return {
                ...state,
                fetchingSingleQuotes: false,
                fetchedSingleQuotesSuccess: null,
                fetchedSingleQuotesFailure: action.payload,
            };
        case orderTypes.DELETE_QUOTE_REQUEST:
            return {
                ...state,
                deleteQuote: true,
                deleteQuoteSuccess: null,
                deleteQuoteFailure: null,
            };
        case orderTypes.DELETE_QUOTE_SUCCESS:
            return {
                ...state,
                deleteQuote: false,
                deleteQuoteSuccess: action.payload,
                deleteQuoteFailure: null,
            };
        case orderTypes.DELETE_QUOTE_FAILURE:
            return {
                ...state,
                deleteQuote: false,
                deleteQuoteSuccess: null,
                deleteQuoteFailure: action.payload,
            };
        case orderTypes.UPDATE_ORDER_REQUEST:
            return {
                ...state,
                updatingOrder: true,
                updateOrderSuccess: null,
                updateOrderFailure: null,
            };
        case orderTypes.UPDATE_ORDER_SUCCESS:
            return {
                ...state,
                updatingOrder: false,
                updateOrderSuccess: action.payload,
                updateOrderFailure: null,
            };
        case orderTypes.UPDATE_ORDER_FAILURE:
            return {
                ...state,
                updatingOrder: false,
                updateOrderSuccess: null,
                updateOrderFailure: action.payload,
            };

        case orderTypes.UPDATE_RFQ_REQUEST:
            return {
                ...state,
                updatingRfq: true,
                updateRfqSuccess: null,
                updateRfqFailure: null,
            };
        case orderTypes.UPDATE_RFQ_SUCCESS:
            return {
                ...state,
                updatingRfq: false,
                updateRfqSuccess: action.payload,
                updateRfqFailure: null,
            };
        case orderTypes.UPDATE_RFQ_FAILURE:
            return {
                ...state,
                updatingRfq: false,
                updateRfqSuccess: null,
                updateRfqFailure: action.payload,
            };

        case orderTypes.CREATE_PURCHASE_ORDER_REQUEST:
            return {
                ...state,
                createPurchaseOrder: true,
                createPurchaseOrderSuccess: null,
                createPurchaseOrderFailure: null,
            };
        case orderTypes.CREATE_PURCHASE_ORDER_SUCCESS:
            return {
                ...state,
                createPurchaseOrder: false,
                createPurchaseOrderSuccess: action.payload,
                createPurchaseOrderFailure: null,
            };
        case orderTypes.CREATE_PURCHASE_ORDER_FAILURE:
            return {
                ...state,
                createPurchaseOrder: false,
                createPurchaseOrderSuccess: null,
                createPurchaseOrderFailure: action.payload,
            };

        case orderTypes.CREATE_QUOTE_REQUEST:
            return {
                ...state,
                createQuote: true,
                createQuoteSuccess: null,
                createQuoteFailure: null,
            };
        case orderTypes.CREATE_QUOTE_SUCCESS:
            return {
                ...state,
                createQuote: false,
                createQuoteSuccess: action.payload,
                createQuoteFailure: null,
            };
        case orderTypes.CREATE_QUOTE_FAILURE:
            return {
                ...state,
                createQuote: false,
                createQuoteSuccess: null,
                createQuoteFailure: action.payload,
            };

        case orderTypes.UPDATE_QUOTE_REQUEST:
            return {
                ...state,
                updateQuote: true,
                updateQuoteSuccess: null,
                updateQuoteFailure: null,
            };
        case orderTypes.UPDATE_QUOTE_SUCCESS:
            return {
                ...state,
                updateQuote: false,
                updateQuoteSuccess: action.payload,
                updateQuoteFailure: null,
            };
        case orderTypes.UPDATE_QUOTE_FAILURE:
            return {
                ...state,
                updateQuote: false,
                updateQuoteSuccess: null,
                updateQuoteFailure: action.payload,
            };

        case orderTypes.SEND_QUOTE_REQUEST:
            return {
                ...state,
                sendQuote: true,
                sendQuoteSuccess: null,
                sendQuoteFailure: null,
            };
        case orderTypes.SEND_QUOTE_SUCCESS:
            return {
                ...state,
                sendQuote: false,
                sendQuoteSuccess: action.payload,
                sendQuoteFailure: null,
            };
        case orderTypes.SEND_QUOTE_FAILURE:
            return {
                ...state,
                sendQuote: false,
                sendQuoteSuccess: null,
                sendQuoteFailure: action.payload,
            };

        case orderTypes.DECLINE_RFQS_REQUEST:
            return {
                ...state,
                declineRfqs: true,
                declineRfqsSuccess: null,
                declineRfqsFailure: null,
            };
        case orderTypes.DECLINE_RFQS_SUCCESS:
            return {
                ...state,
                declineRfqs: false,
                declineRfqsSuccess: action.payload,
                declineRfqsFailure: null,
            };
        case orderTypes.DECLINE_RFQS_FAILURE:
            return {
                ...state,
                declineRfqs: false,
                declineRfqsSuccess: null,
                declineRfqsFailure: action.payload,
            };

        case orderTypes.FETCH_VENDOR_NOTIFICATION_REQUEST:
            return {
                ...state,
                fetchingVendorNotification: true,
                fetchedVendorNotificationSuccess: null,
                fetchedVendorNotificationFailure: null,
            };
        case orderTypes.FETCH_VENDOR_NOTIFICATION_SUCCESS:
            return {
                ...state,
                fetchingVendorNotification: false,
                fetchedVendorNotificationSuccess: action.payload,
                fetchedVendorNotificationFailure: null,
            };
        case orderTypes.FETCH_VENDOR_NOTIFICATION_FAILURE:
            return {
                ...state,
                fetchingVendorNotification: false,
                fetchedVendorNotificationSuccess: null,
                fetchedVendorNotificationFailure: action.payload,
            };

        case orderTypes.READ_ONE_VENDOR_NOTIFICATION_REQUEST:
            return {
                ...state,
                readOneVendorNotification: true,
                readOneVendorNotificationSuccess: null,
                readOneVendorNotificationFailure: null,
            };
        case orderTypes.READ_ONE_VENDOR_NOTIFICATION_SUCCESS:
            return {
                ...state,
                readOneVendorNotification: false,
                readOneVendorNotificationSuccess: action.payload,
                readOneVendorNotificationFailure: null,
            };
        case orderTypes.READ_ONE_VENDOR_NOTIFICATION_FAILURE:
            return {
                ...state,
                readOneVendorNotification: false,
                readOneVendorNotificationSuccess: null,
                readOneVendorNotificationFailure: action.payload,
            };

        case orderTypes.UPDATE_PURCHASE_ORDER_REQUEST:
            return {
                ...state,
                updatePurchaseOrder: true,
                updatePurchaseOrderSuccess: null,
                updatePurchaseOrderFailure: null,
            };
        case orderTypes.UPDATE_PURCHASE_ORDER_SUCCESS:
            return {
                ...state,
                updatePurchaseOrder: false,
                updatePurchaseOrderSuccess: action.payload,
                updatePurchaseOrderFailure: null,
            };
        case orderTypes.UPDATE_PURCHASE_ORDER_FAILURE:
            return {
                ...state,
                updatePurchaseOrder: false,
                updatePurchaseOrderSuccess: null,
                updatePurchaseOrderFailure: action.payload,
            };
        case orderTypes.FETCH_PURCHASE_ORDER_REQUEST:
            return {
                ...state,
                fetchingPurchaseOrder: true,
                fetchedPurchaseOrderSuccess: null,
                fetchedPurchaseOrderFailure: null,
            };
        case orderTypes.FETCH_PURCHASE_ORDER_SUCCESS:
            return {
                ...state,
                fetchingPurchaseOrder: false,
                fetchedPurchaseOrderSuccess: action.payload,
                fetchedPurchaseOrderFailure: null,
            };
        case orderTypes.FETCH_PURCHASE_ORDER_FAILURE:
            return {
                ...state,
                fetchingPurchaseOrder: false,
                fetchedPurchaseOrderSuccess: null,
                fetchedPurchaseOrderFailure: action.payload,
            };
        case orderTypes.SEND_PURCHASE_ORDER_REQUEST:
            return {
                ...state,
                sendPurchaseOrder: true,
                sendPurchaseOrderSuccess: null,
                sendPurchaseOrderFailure: null,
            };
        case orderTypes.SEND_PURCHASE_ORDER_SUCCESS:
            return {
                ...state,
                sendPurchaseOrder: false,
                sendPurchaseOrderSuccess: action.payload,
                sendPurchaseOrderFailure: null,
            };
        case orderTypes.SEND_PURCHASE_ORDER_FAILURE:
            return {
                ...state,
                sendPurchaseOrder: false,
                sendPurchaseOrderSuccess: null,
                sendPurchaseOrderFailure: action.payload,
            };
        case orderTypes.FETCH_APPROVED_RESPONSE_REQUEST:
            return {
                ...state,
                fetchingApprovedResponses: true,
                fetchedApprovedResponsesSuccess: null,
                fetchedApprovedResponsesFailure: null,
            };
        case orderTypes.FETCH_APPROVED_RESPONSE_SUCCESS:
            return {
                ...state,
                fetchingApprovedResponses: false,
                fetchedApprovedResponsesSuccess: action.payload,
                fetchedApprovedResponsesFailure: null,
            };
        case orderTypes.FETCH_APPROVED_RESPONSE_FAILURE:
            return {
                ...state,
                fetchingApprovedResponses: false,
                fetchedApprovedResponsesSuccess: null,
                fetchedApprovedResponsesFailure: action.payload,
            };

        default:
            return state;
    }
};
