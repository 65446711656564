import React from "react";

const TabsContainer = ({
    tabs,
    className,
    button,
    children,
    component,
    setActiveTab,
    activeTab,
    hideBottomBorder,
}) => {
    return (
        <div className="w-full">
            <div className="w-full">
                <div className=" justify-between ">
                    <div className={`flex  gap-x-8 ${button && "mt-2"} px-2 ${className}`}>
                        {tabs?.map((value, idx) => (
                            <p
                                key={value + idx}
                                onClick={() => setActiveTab(idx)}
                                className={` text-sm font-rocGroteskMedium pt-[6.5px] pb-2 cursor-pointer ${
                                    activeTab === idx
                                        ? "text-slate-900 border-slate-900 border-b-[2px]"
                                        : "text-slate-500"
                                }`}
                            >
                                {value}
                            </p>
                        ))}
                    </div>
                </div>

                {!hideBottomBorder && <hr className="text-slate-200" />}
                {(activeTab === 0 || activeTab === 1) && button && <>{children}</>}
            </div>
            <div className="relative">{component}</div>
        </div>
    );
};

export default TabsContainer;
