import Loader from "components/common/Loader";
import { onScroll } from "helpers";
import { truncate } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { orderActions } from "redux/actions";

export const UnRead = ({ nav }) => {
  const [notifications, setNotifications] = useState([]);
  const [data, setData] = useState([]);
  const [unread, setUnread] = useState(true);
  const limit = 20;
  const [notifyLimit, setNotifyLimit] = useState(limit);
  const [loadMore, setLoadMore] = useState(false);
  const [notifyPagination, setNotifyPagination] = useState({
    current: "",
    number_of_pages: "",
  });
  const {
    fetchingVendorNotification,
    readOneVendorNotificationSuccess,
    fetchedVendorNotificationSuccess,
  } = useSelector((state) => state.order);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dashboardContent = document.getElementById("dashboardContent");
  const onNotification = useCallback(
    () =>
      onScroll(
        dashboardContent,
        notifyPagination?.current,
        notifyPagination?.number_of_pages,
        () => {
          setNotifyLimit(() => notifyLimit + limit);
          setLoadMore(true);
        }
      ),
    [dashboardContent, notifyPagination]
  );
  useEffect(() => {
    dashboardContent?.addEventListener("scroll", onNotification);
    return () => {
      dashboardContent?.removeEventListener("scroll", onNotification);
    };
  }, [dashboardContent, onNotification]);

  useEffect(() => {
    dispatch(orderActions.getVendorNotification(unread));
  }, [dispatch, unread]);

  useEffect(() => {
    if (Boolean(fetchedVendorNotificationSuccess)) {
      setNotifications(fetchedVendorNotificationSuccess?.notifications);
      setNotifyPagination({
        current: fetchedVendorNotificationSuccess?.pagination?.current,
        number_of_pages:
          fetchedVendorNotificationSuccess?.pagination?.number_of_pages,
      });
    }
  }, [fetchedVendorNotificationSuccess]);

  useEffect(() => {
    if (Boolean(readOneVendorNotificationSuccess)) {
      navigate(
        `/dashboard/${data?.notificationType?.toLowerCase()}/${data?.dataId}`
      );
      window.location.reload();
      dispatch(orderActions.resetReadOneNotification());
    }
  }, [readOneVendorNotificationSuccess, dispatch, navigate]);

  const limitedNotifications =
    nav && notifications.length > 4 ? notifications.slice(0, 4) : notifications;

  const onMarkRead = (n) => {
    setData(n);
    dispatch(orderActions.readOneVendorNotification(n?._id));
  };

  return (
    <div>
      {limitedNotifications?.length > 0 &&
        limitedNotifications?.map((n) => (
          <div
            key={n?._id}
            className="py-5 w-full border-b cursor-pointer border-b-slate-100 flex items-center space-x-4"
            onClick={() => onMarkRead(n)}
          >
            <div className="w-8 h-8">
              <img src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1694624630/Tradeally_2.0/undefined_-_Imgur_1_prpxel.jpg" />
            </div>
            <div className="font-rocGroteskMedium space-y-0.5">
              <h2 className="text-slate-950 text-sm ">{n?.title}</h2>
              <p className="text-slate-800 text-xs ">
                {truncate(n?.body, { length: `${nav ? 63 : 280}` })}
              </p>
              <p className="text-slate-500 text-xs">
                {moment(n?.createdAt).format("DD MMM YYYY | LT")}
              </p>
            </div>
            <div className="">
              <div className="h-2 w-2  rounded-full bg-[#FF3347]"></div>
            </div>
          </div>
        ))}
      {limitedNotifications?.length === 0 && (
        <div className="my-7 text-center">
          <p>No unread data!!</p>
        </div>
      )}
      {fetchingVendorNotification && loadMore && (
        <div className="flex my-10 justify-center">
          <Loader color="gm-25" size={4} />
        </div>
      )}
      {notifyPagination?.current === notifyPagination?.number_of_pages &&
        !nav && (
          <div className="flex my-4 justify-center text-sm font-rocGroteskMedium">
            End of list
          </div>
        )}
    </div>
  );
};
