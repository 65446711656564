export const sidebarLinks = [
    {
        title: "Home",
        icons: "ri-home-3-line",
        link: "/dashboard",
    },
    {
        title: "Orders",
        icons: "ri-file-list-3-line",
        link: [
            "/dashboard/rfqs",
            "/dashboard/quotes",
            "/dashboard/purchase-orders",
            "/dashboard/rfq",
            "/rfqs",
            "/quotes",
            "/purchase-orders",
            "/rfq",
        ],
        children: [
            {
                title: "RFQs",
                link: "/dashboard/rfqs",
            },
            {
                title: "Quotes",
                link: "/dashboard/quotes",
            },
            {
                title: "Purchase Orders",
                link: "/dashboard/purchase-orders",
            },
        ],
    },
    {
        title: "Shipment",
        icons: "ri-box-1-line",
        link: "/dashboard/shipments",
    },
    {
        title: "Settings",
        icons: "ri-settings-4-line",
        link: "/dashboard/settings",
    },
];
