import { configureStore } from "@reduxjs/toolkit";
import { authVendor, chat, order, shipment } from "redux/reducers";

const store = configureStore({
    reducer: {
        authVendor,
        chat,
        order,
        shipment,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: true, immutableCheck: true }),
});

export default store;
