import { Toaster } from "react-hot-toast";
import { useRoutes } from "react-router-dom";
import routes from "routes";
import { onMessageListener } from "services/firebaseService";

function App() {
    const allRoutes = useRoutes(routes);

    onMessageListener()
        .then((payload) => {
            console.log("payload", payload);
        })
        .catch((err) => console.log("failed"));
    return (
        <>
            <Toaster position="top-right" />

            <div className="App">{allRoutes}</div>
        </>
    );
}

export default App;
