import config from "config/config";
import { getFromStorage, removeFromStorage, saveToStorage } from "helpers";
import jwtDecode from "jwt-decode";

export const authService = {
    apiGate,
    register,
    saveAccount,
    verifyByMail,
    resendVerifyCode,
    changeVerificationEmail,
    loggedIn,
    loginUser,
    resetPassword,
    forgetPassword,
    completeOnboarding,
    getVendorProfile,
    updateVendorProfile,
    logout,
};

function saveAccount(user, remember) {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    saveToStorage("ally-vendor", user, remember);
    window.dispatchEvent(new Event("storage"));
}

function logout() {
    // remove user from local storage to log user out
    removeFromStorage("ally-vendor");
    window.dispatchEvent(new Event("storage"));
}

function isTokenExpired(token) {
    if (!token) return false;

    try {
        const decoded = jwtDecode(token);
        if (decoded.exp < Date.now() / 1000) {
            return true;
        } else return false;
    } catch (err) {
        return false;
    }
}

function loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = getToken(); // Getting token from store

    return !!token && !isTokenExpired(token);
}

async function loginUser(loginObj) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(loginObj),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/vendor/auth/login`,
        requestOptions
    );

    return res;
}

async function apiGate(url, options, noKey) {
    // performs api calls sending the required authentication headers
    let headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
    };

    if (!noKey) {
        headers["x-access-key"] = `Bearer ${config.API_KEY}`;
    }

    if (loggedIn()) {
        headers.Authorization = "Bearer " + getToken();
    }

    if (options?.headers) {
        headers = { ...headers, ...options.headers };
    }
    const { headers: optionHeaders, ...rest } = options;
    try {
        const res = await fetch(url, {
            headers,
            ...rest,
        });

        const response_1 = handleResponse(res);
        return response_1;
    } catch (error) {
        return console.error(error);
    }
}
function getToken() {
    // Retrieves the vendor token from localStorage
    const vendor = getFromStorage("ally-vendor");
    return vendor?.token;
}

function handleResponse(response) {
    return response.text().then((text) => {
        let data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                //    Error alert goes here
                console.log('"Permission denied!"');
            }

            data.status = response.status;
            const error = (data && data) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

async function getVendorProfile(token) {
    const requestOptions = {
        method: "GET",
    };

    if (token) {
        requestOptions.headers = {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
        };
    }

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/vendor/profile`,
        requestOptions
    );

    return res;
}
async function updateVendorProfile(data, token) {
    const requestOptions = {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/vendor/profile`,
        requestOptions
    );

    return res;
}

async function register(registerObj) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(registerObj),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/vendor/auth/signup`,
        requestOptions
    );

    return res;
}

async function verifyByMail(codeObj, token) {
    const requestOptions = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(codeObj),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/vendor/auth/code/signup/verify`,
        requestOptions
    );

    return res;
}

async function changeVerificationEmail(reqObj, token) {
    const requestOptions = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(reqObj),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/vendor/auth/signup/email-change`,
        requestOptions
    );

    return res;
}

async function resendVerifyCode(token) {
    const requestOptions = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({}),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/vendor/auth/code/signup/resend`,
        requestOptions
    );

    return res;
}

async function forgetPassword(reqObj) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(reqObj),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/vendor/auth/password/forget`,
        requestOptions
    );

    return res;
}

async function resetPassword(reqObj, token) {
    const requestOptions = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(reqObj),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/vendor/auth/password/reset`,
        requestOptions
    );

    return res;
}

async function completeOnboarding(body, ldbId, token) {
    const requestOptions = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/vendor/onboard?ldbId=${ldbId}`,
        requestOptions
    );

    return res;
}
