import Loader from "components/common/Loader";
import { PrivateRoutes } from "components/common/Routes/PrivateRoute";
import { PublicRoutes } from "components/common/Routes/PublicRoute";
import Dashboard from "pages/Dashboard";
import { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";

// Public Pages
const VendorSignupSuccess = lazy(() => import("pages/Auth/SignupSuccess"));
const VendorAcctVerification = lazy(() => import("pages/Auth/AcctVerification"));
const VendorChangeVerificationEmail = lazy(() => import("pages/Auth/ChangeVerificationEmail"));
const Login = lazy(() => import("pages/Auth/Login"));
const ResetPassword = lazy(() => import("pages/Auth/ResetPassword"));
const ForgotPassword = lazy(() => import("pages/Auth/ForgotPassword"));
// Private Pages
const VendorSignup = lazy(() => import("pages/Auth/Signup"));
const VendorOnboarding = lazy(() => import("pages/Auth/Onboarding"));
const Rfqs = lazy(() => import("pages/Orders/Rfqs"));
const RfqDetails = lazy(() => import("pages/Orders/Rfqs/RfqDetails"));
const CreateQuote = lazy(() => import("pages/Orders/Quotes/createQuotes"));
const EditQuote = lazy(() => import("pages/Orders/Quotes/editQuotes"));
const Quotes = lazy(() => import("pages/Orders/Quotes/index"));
const QuotesDetails = lazy(() => import("pages/Orders/Quotes/QuoteDetails"));
const PurchaseOrder = lazy(() => import("pages/Orders/PurchaseOrder/index"));
const PurchaseOrderDetails = lazy(() => import("pages/Orders/PurchaseOrder/PurchaseOrderDetails"));
const Notifications = lazy(() => import("pages/Notifications/index"));
const Shipment = lazy(() => import("pages/Shipment/index"));
const CreateShipment = lazy(() => import("pages/Shipment/CreateShipment"));
const ShipmentDetails = lazy(() => import("pages/Shipment/ShipmentDetails"));
const Settings = lazy(() => import("pages/Settings/index"));
const DashboardHome = lazy(() => import("pages/Home/index"));

const routes = [
    {
        path: "/",
        element: <Navigate to={"/login"} replace />,
    },
    {
        path: "/register",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <PublicRoutes>
                    <VendorSignup />
                </PublicRoutes>
            </Suspense>
        ),
    },

    {
        path: "/registration-success/:token",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <PublicRoutes>
                    <VendorSignupSuccess />
                </PublicRoutes>
            </Suspense>
        ),
    },
    {
        path: "/login",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <PublicRoutes>
                    <Login />
                </PublicRoutes>
            </Suspense>
        ),
    },
    {
        path: "/verify-email",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <PublicRoutes>
                    <VendorAcctVerification />
                </PublicRoutes>
            </Suspense>
        ),
    },
    {
        path: "/forgot-password",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <PublicRoutes>
                    <ForgotPassword />
                </PublicRoutes>
            </Suspense>
        ),
    },
    {
        path: "/reset-password",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <PublicRoutes>
                    <ResetPassword />
                </PublicRoutes>
            </Suspense>
        ),
    },
    {
        path: "/verify",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <PublicRoutes>
                    <VendorAcctVerification />
                </PublicRoutes>
            </Suspense>
        ),
    },

    {
        path: "/change-email/:token",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <PublicRoutes>
                    <VendorChangeVerificationEmail />
                </PublicRoutes>
            </Suspense>
        ),
    },
    {
        path: "/onboarding",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <PrivateRoutes>
                    <VendorOnboarding />
                </PrivateRoutes>
            </Suspense>
        ),
    },
    {
        path: "/dashboard",
        element: <Dashboard />,
        children: [
              {
                index: true,
                element: <DashboardHome />,
              },
            {
                path: "rfqs",
                element: <Rfqs />,
            },
            {
                path: "rfq/:rfqId",
                element: <RfqDetails />,
            },

            {
                path: "quotes/create",
                element: <CreateQuote />,
            },
            {
                path: "quotes/edit",
                element: <EditQuote />,
            },
            {
                path: "notifications",
                element: <Notifications />,
            },
            {
                path: "quotes",
                element: <Quotes />,
            },
            {
                path: "quoteresponse/:quoteId",
                element: <QuotesDetails />,
            },
            {
                path: "purchase-orders",
                element: <PurchaseOrder />,
            },
            {
                path: "purchase-orders/:poId",
                element: <PurchaseOrderDetails />,
            },
            {
                path: "shipments",
                element: <Shipment />,
            },
            {
                path: "shipment/create",
                element: <CreateShipment />,
            },
            {
                path: "shipment/edit/:shipmentId",
                element: <CreateShipment />,
            },
            {
                path: "shipment/:shipmentId",
                element: <ShipmentDetails />,
            },
            {
                path: "settings",
                element: <Settings />,
            },
        ],
    },
    {
        path: "*",
        element: (
            <div className="h-screen flex flex-col items-center justify-center">
                <p className="font-rocGroteskMedium text-2xl mb-5">404</p>
                <p className="font-rocGroteskMedium text-lg mb-5">PAGE NOT FOUND</p>
            </div>
        ),
    },
];

export default routes;
