import config from "config/config";
import { authService } from "./authService";
import { createUrlSearchParams } from "helpers";

export const orderService = {
    getRfqs,
    getOrders,
    getSingleRfq,
    getSinglePurchaseOrder,
    getQuotes,
    getSingleQuotes,
    deleteQuote,
    updateOrder,
    updateRfq,
    getPurchaseOrder,
    sendPurchaseOrder,
    getApprovedResponses,
    createQuote,
    updateQuote,
    declineRfqs,
    sendQuote,
    getVendorNotification,
    readOneVendorNotification,
};

async function getRfqs(rfqLimit, search, vendor, orderId, amount) {
    const requestOptions = {
        method: "GET",
    };
    const urlParams = createUrlSearchParams({
        search,
        vendor,
        taId: orderId,
        amount,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/vendor/rfq/list/rfqs?limit=${rfqLimit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function getVendorNotification(unread) {
    const requestOptions = {
        method: "GET",
    };
    const urlParams = createUrlSearchParams({
        unread,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/vendor/notification/list/${
            Boolean(urlParams?.toString()) ? "?" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function readOneVendorNotification(notificationId) {
    const requestOptions = {
        method: "PUT",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/vendor/notification/mark/${notificationId}`,
        requestOptions
    );
    return res;
}

async function getSingleRfq(rfqId) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/vendor/rfq/single/rfq/${rfqId}`,
        requestOptions
    );
    return res;
}

async function getOrders(orderLimit) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/rfq/order?limit=${orderLimit}`,
        requestOptions
    );
    return res;
}

async function getSinglePurchaseOrder(poId) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/vendor/po/single/purchaseOrder/${poId}`,
        requestOptions
    );
    return res;
}

async function updateOrder(orderId, data) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/rfq/update/order/${orderId}`,
        requestOptions
    );
    return res;
}

async function updateRfq(rfqId, data) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/rfq/update/rfq/${rfqId}`,
        requestOptions
    );
    return res;
}

async function getQuotes(quoteLimit, search, vendor, quoteId, amount) {
    const requestOptions = {
        method: "GET",
    };
    const urlParams = createUrlSearchParams({
        search,
        vendor,
        taId: quoteId,
        amount,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/vendor/rfq/list/responses?limit=${quoteLimit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function getSingleQuotes(quoteId) {
    const requestOptions = {
        method: "GET",
    };
    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/vendor/rfq/single/quoteResponse/${quoteId}`,
        requestOptions
    );
    return res;
}

async function getApprovedResponses(limit, search) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        search,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/vendor/rfq/list/responses?limit=${limit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function deleteQuote(id) {
    const requestOptions = {
        method: "DELETE",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/vendor/rfq/single/quoteResponse/${id}`,
        requestOptions
    );

    return res;
}

async function createQuote(data) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/vendor/rfq/response`,
        requestOptions
    );

    return res;
}

async function updateQuote(data, quoteId) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/vendor/rfq/update/response/${quoteId}`,
        requestOptions
    );

    return res;
}

async function sendQuote(data) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/vendor/rfq/send/response`,
        requestOptions
    );

    return res;
}

async function declineRfqs(data, rfqId) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/vendor/rfq/response/decline/${rfqId}`,
        requestOptions
    );

    return res;
}

async function getPurchaseOrder(poLimit, search, taId) {
    const requestOptions = {
        method: "GET",
    };
    const urlParams = createUrlSearchParams({
        search,
        taId,
    });
    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/vendor/po/list?limit=${poLimit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}
async function sendPurchaseOrder(poId, data) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/po/send/purchase-order/${poId}`,
        requestOptions
    );

    return res;
}
