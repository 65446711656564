import config from "config/config";
import { authService } from "./authService";

export const shipmentService = {
    createShipment,
    updateShipment,
    getShipments,
    getSingleShipment,
    getPorts,
    updateShipmentDocument,
    updateBOL,
    sendStatusUpdate,
};

async function createShipment(data) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/vendor/shipment`,
        requestOptions
    );
    return res;
}

async function updateShipment(data, shipmentId) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/vendor/shipment/update/shipment/${shipmentId}`,
        requestOptions
    );
    return res;
}

async function getShipments(shipmentLimit, search) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/vendor/shipment/list?limit=${shipmentLimit}${
            Boolean(search) ? "&search=" + search : ""
        }`,
        requestOptions
    );
    return res;
}

async function getSingleShipment(shipmentId) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/vendor/shipment/single/shipment/${shipmentId}`,
        requestOptions
    );
    return res;
}

async function getPorts(search = "united") {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/vendor/shipment/search/ports?search=${search}`,
        requestOptions
    );
    return res;
}

async function updateShipmentDocument(data) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/vendor/shipment/update/documents`,
        requestOptions
    );
    return res;
}

async function updateBOL(data) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/vendor/shipment/update/billofLadin`,
        requestOptions
    );
    return res;
}

async function sendStatusUpdate(data, shipmentId) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/vendor/shipment/status/update/${shipmentId}`,
        requestOptions
    );
    return res;
}
