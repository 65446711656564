export const shipmentTypes = {
    CREATE_SHIPMENT_REQUEST: "CREATE_SHIPMENT_REQUEST",
    CREATE_SHIPMENT_SUCCESS: "CREATE_SHIPMENT_SUCCESS",
    CREATE_SHIPMENT_FAILURE: "CREATE_SHIPMENT_FAILURE",
    UPDATE_SHIPMENT_REQUEST: "UPDATE_SHIPMENT_REQUEST",
    UPDATE_SHIPMENT_SUCCESS: "UPDATE_SHIPMENT_SUCCESS",
    UPDATE_SHIPMENT_FAILURE: "UPDATE_SHIPMENT_FAILURE",
    FETCH_SHIPMENTS_REQUEST: "FETCH_SHIPMENTS_REQUEST",
    FETCH_SHIPMENTS_SUCCESS: "FETCH_SHIPMENTS_SUCCESS",
    FETCH_SHIPMENTS_FAILURE: "FETCH_SHIPMENTS_FAILURE",
    FETCH_SINGLE_SHIPMENT_REQUEST: "FETCH_SINGLE_SHIPMENT_REQUEST",
    FETCH_SINGLE_SHIPMENT_SUCCESS: "FETCH_SINGLE_SHIPMENT_SUCCESS",
    FETCH_SINGLE_SHIPMENT_FAILURE: "FETCH_SINGLE_SHIPMENT_FAILURE",
    FETCH_PORTS_REQUEST: "FETCH_PORTS_REQUEST",
    FETCH_PORTS_SUCCESS: "FETCH_PORTS_SUCCESS",
    FETCH_PORTS_FAILURE: "FETCH_PORTS_FAILURE",
    UPDATE_SHIPMENT_DOCUMENT_REQUEST: "UPDATE_SHIPMENT_DOCUMENT_REQUEST",
    UPDATE_SHIPMENT_DOCUMENT_SUCCESS: "UPDATE_SHIPMENT_DOCUMENT_SUCCESS",
    UPDATE_SHIPMENT_DOCUMENT_FAILURE: "UPDATE_SHIPMENT_DOCUMENT_FAILURE",
    UPDATE_BOL_REQUEST: "UPDATE_BOL_REQUEST",
    UPDATE_BOL_SUCCESS: "UPDATE_BOL_SUCCESS",
    UPDATE_BOL_FAILURE: "UPDATE_BOL_FAILURE",
    SEND_STATUS_UPDATE_REQUEST: "SEND_STATUS_UPDATE_REQUEST",
    SEND_STATUS_UPDATE_SUCCESS: "SEND_STATUS_UPDATE_SUCCESS",
    SEND_STATUS_UPDATE_FAILURE: "SEND_STATUS_UPDATE_FAILURE",
};
