import CustomToast from "components/common/CustomToast";
import { shipmentTypes } from "redux/types";
import { toast } from "react-hot-toast";
import { shipmentService } from "services";

export const shipmentActions = {
    createShipment,
    resetCreateShipmentSuccess,
    updateShipment,
    resetUpdateShipmentSuccess,
    getShipments,
    getSingleShipment,
    getPorts,
    updateShipmentDocument,
    resetUpdateShipmentDocumentSuccess,
    updateBOL,
    resetUpdateBOLSuccess,
    sendStatusUpdate,
    resetSendStatusUpdateSuccess,
};

function createShipment(data) {
    return (dispatch) => {
        dispatch(request(shipmentTypes.CREATE_SHIPMENT_REQUEST));

        shipmentService.createShipment(data).then(
            (res) => {
                dispatch(success(shipmentTypes.CREATE_SHIPMENT_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Shipment created"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(shipmentTypes.CREATE_SHIPMENT_FAILURE, error?.message));
                }
            }
        );
    };
}

function updateShipment(data, shipmentId) {
    return (dispatch) => {
        dispatch(request(shipmentTypes.UPDATE_SHIPMENT_REQUEST));

        shipmentService.updateShipment(data, shipmentId).then(
            (res) => {
                dispatch(success(shipmentTypes.UPDATE_SHIPMENT_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Shipment updated"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(shipmentTypes.UPDATE_SHIPMENT_FAILURE, error?.message));
                }
            }
        );
    };
}

function getShipments(shipmentLimit, debounceSearch) {
    return (dispatch) => {
        dispatch(request(shipmentTypes.FETCH_SHIPMENTS_REQUEST));

        shipmentService.getShipments(shipmentLimit, debounceSearch).then(
            (res) => {
                dispatch(success(shipmentTypes.FETCH_SHIPMENTS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(shipmentTypes.FETCH_SHIPMENTS_FAILURE, error?.message));
                }
            }
        );
    };
}

function getSingleShipment(shipmentId) {
    return (dispatch) => {
        dispatch(request(shipmentTypes.FETCH_SINGLE_SHIPMENT_REQUEST));

        shipmentService.getSingleShipment(shipmentId).then(
            (res) => {
                dispatch(success(shipmentTypes.FETCH_SINGLE_SHIPMENT_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(shipmentTypes.FETCH_SINGLE_SHIPMENT_FAILURE, error?.message));
                }
            }
        );
    };
}

function getPorts(search) {
    return (dispatch) => {
        dispatch(request(shipmentTypes.FETCH_PORTS_REQUEST));

        shipmentService.getPorts(search).then(
            (res) => {
                dispatch(success(shipmentTypes.FETCH_PORTS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(shipmentTypes.FETCH_PORTS_FAILURE, error?.message));
                }
            }
        );
    };
}

function updateShipmentDocument(data) {
    return (dispatch) => {
        dispatch(request(shipmentTypes.UPDATE_SHIPMENT_DOCUMENT_REQUEST));

        shipmentService.updateShipmentDocument(data).then(
            (res) => {
                dispatch(success(shipmentTypes.UPDATE_SHIPMENT_DOCUMENT_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Document updated"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(shipmentTypes.UPDATE_SHIPMENT_DOCUMENT_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function updateBOL(data) {
    return (dispatch) => {
        dispatch(request(shipmentTypes.UPDATE_BOL_REQUEST));

        shipmentService.updateBOL(data).then(
            (res) => {
                dispatch(success(shipmentTypes.UPDATE_BOL_SUCCESS, res?.data));
                toast.custom((t) => <CustomToast t={t} message={"BOL updated"} type="success" />);
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(shipmentTypes.UPDATE_BOL_FAILURE, error?.message));
                }
            }
        );
    };
}

function sendStatusUpdate(data, shipmentId) {
    return (dispatch) => {
        dispatch(request(shipmentTypes.SEND_STATUS_UPDATE_REQUEST));

        shipmentService.sendStatusUpdate(data, shipmentId).then(
            (res) => {
                dispatch(success(shipmentTypes.SEND_STATUS_UPDATE_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Shipment updated"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(shipmentTypes.SEND_STATUS_UPDATE_FAILURE, error?.message));
                }
            }
        );
    };
}

function resetSendStatusUpdateSuccess() {
    return (dispatch) => {
        dispatch(success(shipmentTypes.SEND_STATUS_UPDATE_SUCCESS, null));
    };
}

function resetUpdateBOLSuccess() {
    return (dispatch) => {
        dispatch(success(shipmentTypes.UPDATE_BOL_SUCCESS, null));
    };
}

function resetUpdateShipmentDocumentSuccess() {
    return (dispatch) => {
        dispatch(success(shipmentTypes.UPDATE_SHIPMENT_DOCUMENT_SUCCESS, null));
    };
}

function resetUpdateShipmentSuccess() {
    return (dispatch) => {
        dispatch(success(shipmentTypes.UPDATE_SHIPMENT_SUCCESS, null));
    };
}

function resetCreateShipmentSuccess() {
    return (dispatch) => {
        dispatch(success(shipmentTypes.CREATE_SHIPMENT_SUCCESS, null));
    };
}

function request(type) {
    return { type: type };
}
function success(type, data) {
    return { type: type, payload: data };
}
function failure(type, error) {
    return { type: type, payload: error ?? "" };
}
