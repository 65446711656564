// const dev = {
//     API_KEY: "EV4KG3C-KTWMB96-G8REDF9-JF88AAR",
//     API_URL: "https://dev.api.tradeally.io",
//     PUBLIC_FINCRA_API_KEY: "pk_test_NjM4NzdhYzZkOGI0NTNhYjg3YmEyYTQ1OjoxMjUyODY=",
//     PUBLIC_PAYSTACK_API_KEY: "pk_test_57e68c51fceab57fae3065673e2cc6bea07efa62",
//     GOOGLE_API_KEY: "AIzaSyC0zTXUQVdPElYId3ox4fw_5JTvGwOJ15Y",
//     FB: {
//         apiKey: "AIzaSyC0zTXUQVdPElYId3ox4fw_5JTvGwOJ15Y",
//         authDomain: "durable-path-390718.firebaseapp.com",
//         databaseURL: "https://durable-path-390718-default-rtdb.firebaseio.com",
//         projectId: "durable-path-390718",
//         storageBucket: "durable-path-390718.appspot.com",
//         messagingSenderId: "300269143059",
//         appId: "1:300269143059:web:5c20846e9ae695ea62abe6",
//         measurementId: "G-W1YVS6HMLW",
//     },
// };

const staging = {
    API_KEY: "EV4KG3C-KTWMB96-G8REDF9-JF88AAR",
    API_URL: "https://staging.api.tradeally.io",
    PUBLIC_FINCRA_API_KEY: "pk_test_NjM4NzdhYzZkOGI0NTNhYjg3YmEyYTQ1OjoxMjUyODY=",
    PUBLIC_PAYSTACK_API_KEY: "pk_test_57e68c51fceab57fae3065673e2cc6bea07efa62",
    GOOGLE_API_KEY: "AIzaSyC0zTXUQVdPElYId3ox4fw_5JTvGwOJ15Y",
    TINYMICE_API:"ay6cqxykpplo5mh7b9q58ppzdg8f8swhqq7vokrgdj1x20gd",
    FB: {
        apiKey: "AIzaSyC0zTXUQVdPElYId3ox4fw_5JTvGwOJ15Y",
        authDomain: "durable-path-390718.firebaseapp.com",
        databaseURL: "https://durable-path-390718-default-rtdb.firebaseio.com",
        projectId: "durable-path-390718",
        storageBucket: "durable-path-390718.appspot.com",
        messagingSenderId: "300269143059",
        appId: "1:300269143059:web:5c20846e9ae695ea62abe6",
        measurementId: "G-W1YVS6HMLW",
    },
        VAPID_KEY:
        "BH9PLKi5t2yXJTKLEMaFq9SbORjEMmbuBbViLIgZOqv7MWEunUpZJ-4-Xj8YKzCcJDs_tOQ7T5H5llrZLwWjUXo",
};

const prod = {
    API_KEY: "M4C0F2Q-5E24DEK-KV671VN-GFBE17Z",
    API_URL: "https://api.tradeally.io",
    PUBLIC_FINCRA_API_KEY: "pk_NjM4NzdhYzRkMGNkNjMzZmUzZjQyYzQ1OjoxODkxMzk=",
    PUBLIC_PAYSTACK_API_KEY: "",
    GOOGLE_API_KEY: "AIzaSyC0zTXUQVdPElYId3ox4fw_5JTvGwOJ15Y",
    TINYMICE_API: "ay6cqxykpplo5mh7b9q58ppzdg8f8swhqq7vokrgdj1x20gd",
    FB: {
        apiKey: "AIzaSyDp-ar6IBW2PVLCnCUTe7Q91B_A9BfzKNw",
        authDomain: "ta-prod-394010.firebaseapp.com",
        databaseURL: "https://ta-prod-394010-default-rtdb.firebaseio.com",
        projectId: "ta-prod-394010",
        storageBucket: "ta-prod-394010.appspot.com",
        messagingSenderId: "490327246251",
        appId: "1:490327246251:web:3f88884a7fce46be627eee",
        measurementId: "G-PVKXG6GKTM",
    },

    VAPID_KEY:
        "BH9PLKi5t2yXJTKLEMaFq9SbORjEMmbuBbViLIgZOqv7MWEunUpZJ-4-Xj8YKzCcJDs_tOQ7T5H5llrZLwWjUXo",
};

const config = process.env.REACT_APP_STAGE === "production" ? prod : staging;

export default config;
