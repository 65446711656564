import { authTypes } from "../types";

const initialState = {
  registeringVendor: false,
  registerVendorSuccess: null,
  registerVendorFailure: null,
  vendorLoggingIn: false,
  vendorLoggedInSuccess: null,
  vendorLoggedInFailure: null,
  forgotPasswordRequest: false,
  forgotPasswordSuccess: null,
  forgotPasswordFailure: null,
  resettingPassword: false,
  resetPasswordSuccess: null,
  resetPasswordFailure: null,
  verifyingVendor: false,
  verifyVendorSuccess: null,
  verifyVendorFailure: null,
  resendingVerificationCode: false,
  resendVerificationCodeSuccess: null,
  resendVerificationCodeFailure: null,
  changingSignupEmail: false,
  changeSignupEmailSuccess: null,
  changeSignupEmailFailure: null,
  completeOnboarding: false,
  completeOnboardingSuccess: null,
  completeOnboardingFailure: null,
  fetchVendorProfileRequest: false,
  fetchVendorProfileSuccess: null,
  fetchVendorProfileFailure: null,
  updateVendorProfileRequest: false,
  updateVendorProfileSuccess: null,
  updateVendorProfileFailure: null,
};

export const authVendor = (state = initialState, action) => {
  switch (action.type) {
    case authTypes.REGISTER_VENDOR_REQUEST:
      return {
        ...state,
        registeringVendor: true,
        registerVendorSuccess: null,
        registerVendorFailure: null,
      };
    case authTypes.REGISTER_VENDOR_SUCCESS:
      return {
        ...state,
        registeringVendor: false,
        registerVendorSuccess: action.payload,
        registerVendorFailure: null,
      };
    case authTypes.REGISTER_VENDOR_FAILURE:
      return {
        ...state,
        registeringVendor: false,
        registerVendorSuccess: null,
        registerVendorFailure: action.payload,
      };
    case authTypes.VENDOR_LOGIN_REQUEST:
      return {
        ...state,
        vendorLoggingIn: true,
        vendorLoggedInSuccess: null,
        vendorLoggedInFailure: null,
      };
    case authTypes.VENDOR_LOGIN_SUCCESS:
      return {
        ...state,
        vendorLoggingIn: false,
        vendorLoggedInSuccess: action.payload,
        vendorLoggedInFailure: null,
      };
    case authTypes.VENDOR_LOGIN_FAILURE:
      return {
        ...state,
        vendorLoggingIn: false,
        vendorLoggedInSuccess: null,
        vendorLoggedInFailure: action.payload,
      };
    case authTypes.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        forgotPasswordRequest: true,
        forgotPasswordSuccess: null,
        forgotPasswordFailure: null,
      };
    case authTypes.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordRequest: false,
        forgotPasswordSuccess: action.payload,
        forgotPasswordFailure: null,
      };
    case authTypes.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        forgotPasswordRequest: false,
        forgotPasswordSuccess: null,
        forgotPasswordFailure: action.payload,
      };

    case authTypes.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resettingPassword: true,
        resetPasswordSuccess: null,
        resetPasswordFailure: null,
      };
    case authTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resettingPassword: false,
        resetPasswordSuccess: action.payload,
        resetPasswordFailure: null,
      };
    case authTypes.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resettingPassword: false,
        resetPasswordSuccess: null,
        resetPasswordFailure: action.payload,
      };

    case authTypes.VERIFY_VENDOR_REQUEST:
      return {
        ...state,
        verifyingVendor: true,
        verifyVendorSuccess: null,
        verifyVendorFailure: null,
      };
    case authTypes.VERIFY_VENDOR_SUCCESS:
      return {
        ...state,
        verifyingVendor: false,
        verifyVendorSuccess: action.payload,
        verifyVendorFailure: null,
      };
    case authTypes.VERIFY_VENDOR_FAILURE:
      return {
        ...state,
        verifyingVendor: false,
        verifyVendorSuccess: null,
        verifyVendorFailure: action.payload,
      };

    case authTypes.RESEND_VERIFY_CODE_REQUEST:
      return {
        ...state,
        resendingVerificationCode: true,
        resendVerificationCodeSuccess: null,
        resendVerificationCodeFailure: null,
      };
    case authTypes.RESEND_VERIFY_CODE_SUCCESS:
      return {
        ...state,
        resendingVerificationCode: false,
        resendVerificationCodeSuccess: action.payload,
        resendVerificationCodeFailure: null,
      };
    case authTypes.RESEND_VERIFY_CODE_FAILURE:
      return {
        ...state,
        resendingVerificationCode: false,
        resendVerificationCodeSuccess: null,
        resendVerificationCodeFailure: action.payload,
      };
    case authTypes.CHANGE_SIGNUP_EMAIL_REQUEST:
      return {
        ...state,
        changingSignupEmail: true,
        changeSignupEmailSuccess: null,
        changeSignupEmailFailure: null,
      };
    case authTypes.CHANGE_SIGNUP_EMAIL_SUCCESS:
      return {
        ...state,
        changingSignupEmail: false,
        changeSignupEmailSuccess: action.payload,
        changeSignupEmailFailure: null,
      };
    case authTypes.CHANGE_SIGNUP_EMAIL_FAILURE:
      return {
        ...state,
        changingSignupEmail: false,
        changeSignupEmailSuccess: null,
        changeSignupEmailFailure: action.payload,
      };

    case authTypes.COMPLETE_ONBOARDING_REQUEST:
      return {
        ...state,
        completeOnboarding: true,
        completeOnboardingSuccess: null,
        completeOnboardingFailure: null,
      };
    case authTypes.COMPLETE_ONBOARDING_SUCCESS:
      return {
        ...state,
        completeOnboarding: false,
        completeOnboardingSuccess: action.payload,
        completeOnboardingFailure: null,
      };
    case authTypes.COMPLETE_ONBOARDING_FAILURE:
      return {
        ...state,
        completeOnboarding: false,
        completeOnboardingSuccess: null,
        completeOnboardingFailure: action.payload,
      };
    case authTypes.FETCH_VENDOR_PROFILE_REQUEST:
      return {
        ...state,
        fetchVendorProfileRequest: true,
        fetchVendorProfileSuccess: null,
        fetchVendorProfileFailure: null,
      };
    case authTypes.FETCH_VENDOR_PROFILE_SUCCESS:
      return {
        ...state,
        fetchVendorProfileRequest: false,
        fetchVendorProfileSuccess: action.payload,
        fetchVendorProfileFailure: null,
      };
    case authTypes.FETCH_VENDOR_PROFILE_FAILURE:
      return {
        ...state,
        fetchVendorProfileRequest: false,
        fetchVendorProfileSuccess: null,
        fetchVendorProfileFailure: action.payload,
      };
    case authTypes.UPDATE_VENDOR_PROFILE_REQUEST:
      return {
        ...state,
        updateVendorProfileRequest: true,
        updateVendorProfileSuccess: null,
        updateVendorProfileFailure: null,
      };
    case authTypes.UPDATE_VENDOR_PROFILE_SUCCESS:
      return {
        ...state,
        updateVendorProfileRequest: false,
        updateVendorProfileSuccess: action.payload,
        updateVendorProfileFailure: null,
      };
    case authTypes.UPDATE_VENDOR_PROFILE_FAILURE:
      return {
        ...state,
        updateVendorProfileRequest: false,
        updateVendorProfileSuccess: null,
        updateVendorProfileFailure: action.payload,
      };

    case authTypes.LOG_OUT:
      return {
        ...state,
        vendorLoggedInSuccess: null,
        fetchVendorProfileSuccess: null,
      };

    default:
      return state;
  }
};
