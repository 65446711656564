import CustomToast from "components/common/CustomToast";
import { toast } from "react-hot-toast";
import { authService } from "services";
import { authTypes } from "../types";
import { getFromStorage, saveToStorage } from "helpers";

export const authActions = {
    register,
    verifyByMail,
    resendVerifyCode,
    changeVerificationEmail,
    completeOnboarding,
    loginUser,
    resetPassword,
    forgotPassword,
    logOut,
    getVendorProfile,
    updateVendorProfile,
};

function register(reqObj) {
    return (dispatch) => {
        dispatch(request(authTypes.REGISTER_VENDOR_REQUEST));
        authService.register(reqObj).then(
            (res) => {
                dispatch(success(authTypes.REGISTER_VENDOR_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(authTypes.REGISTER_VENDOR_FAILURE, error.message));
                }
            }
        );
    };
}

function verifyByMail(body, token, remember) {
    return (dispatch) => {
        dispatch(request(authTypes.VERIFY_VENDOR_REQUEST));
        authService.verifyByMail(body, token).then(
            (res) => {
                const user = {
                    ...res?.data?.user,
                    token: res?.data?.token,
                    verifyComplete: true,
                };
                const userTwo = {
                    ...res?.data,
                };
                authService.saveAccount(userTwo, remember);

                dispatch(success(authTypes.VERIFY_VENDOR_SUCCESS, user));
            },
            (error) => {
                if (error.message) {
                    // toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(authTypes.VERIFY_VENDOR_FAILURE, error.message));
                }
            }
        );
    };
}

function resendVerifyCode(showMessage = true, token) {
    return (dispatch) => {
        dispatch(request(authTypes.RESEND_VERIFY_CODE_REQUEST));
        authService.resendVerifyCode(token).then(
            (res) => {
                showMessage &&
                    toast.custom((t) => (
                        <CustomToast t={t} message={"Verification link resent successfully"} />
                    ));
                dispatch(success(authTypes.RESEND_VERIFY_CODE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(authTypes.RESEND_VERIFY_CODE_FAILURE, error.message));
                }
            }
        );
    };
}
function changeVerificationEmail(reqObj, token) {
    return (dispatch) => {
        dispatch(request(authTypes.CHANGE_SIGNUP_EMAIL_REQUEST));
        authService.changeVerificationEmail(reqObj, token).then(
            (res) => {
                toast.custom((t) => (
                    <CustomToast t={t} message={"Email updated," + res?.message} />
                ));
                dispatch(success(authTypes.CHANGE_SIGNUP_EMAIL_SUCCESS, res?.success));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(authTypes.CHANGE_SIGNUP_EMAIL_FAILURE, error.message));
                }
            }
        );
    };
}

function completeOnboarding(body, ldbId, token, user) {
    return (dispatch) => {
        dispatch(request(authTypes.COMPLETE_ONBOARDING_REQUEST));
        authService.completeOnboarding(body, ldbId, token).then(
            (res) => {
                toast.custom((t) => (
                    <CustomToast t={t} message={"Completed onboarding successfully"} />
                ));
                saveToStorage("ally-vendor", { token, user });
                dispatch(success(authTypes.COMPLETE_ONBOARDING_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(authTypes.COMPLETE_ONBOARDING_FAILURE, error?.message));
                }
            }
        );
    };
}

function loginUser(loginObj, remember) {
    return (dispatch) => {
        dispatch(request(authTypes.VENDOR_LOGIN_REQUEST));
        authService.loginUser(loginObj).then(
            (res) => {
                const user = {
                    ...res?.data.data,
                };
                const storedUser = getFromStorage("ally-vendor");
                if (storedUser) {
                    storedUser.token = user.token;
                    authService.saveAccount(storedUser, remember);
                }
                authService.saveAccount(user, remember);
                dispatch(success(authTypes.VENDOR_LOGIN_SUCCESS, user));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(authTypes.VENDOR_LOGIN_FAILURE, error?.message));
                }
            }
        );
    };
}

function getVendorProfile(token) {
    return (dispatch) => {
        dispatch(request(authTypes.FETCH_VENDOR_PROFILE_REQUEST));

        authService.getVendorProfile(token).then(
            (res) => {
                dispatch(success(authTypes.FETCH_VENDOR_PROFILE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(authTypes.FETCH_VENDOR_PROFILE_FAILURE, error?.message));
                }
            }
        );
    };
}

function updateVendorProfile(data, token) {
    return (dispatch) => {
        dispatch(request(authTypes.UPDATE_VENDOR_PROFILE_REQUEST));
        authService.updateVendorProfile(data, token).then(
            (res) => {
                dispatch(success(authTypes.UPDATE_VENDOR_PROFILE_SUCCESS, res?.data?.user));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(authTypes.UPDATE_VENDOR_PROFILE_FAILURE, error?.message));
                }
            }
        );
    };
}
function forgotPassword(reqObj) {
    return (dispatch) => {
        dispatch(request(authTypes.FORGOT_PASSWORD_REQUEST));
        authService.forgetPassword(reqObj).then(
            (res) => {
                toast.custom((t) => <CustomToast t={t} message={res?.message} />);
                dispatch(success(authTypes.FORGOT_PASSWORD_SUCCESS, res?.success));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(authTypes.FORGOT_PASSWORD_FAILURE, error?.message));
                }
            }
        );
    };
}

function resetPassword(reqObj, token) {
    return (dispatch) => {
        dispatch(request(authTypes.RESET_PASSWORD_REQUEST));
        authService.resetPassword(reqObj, token).then(
            (res) => {
                toast.custom((t) => <CustomToast t={t} message={res.message} />);
                dispatch(success(authTypes.RESET_PASSWORD_SUCCESS, res.success));
                window.location.href = "/login";
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(authTypes.RESET_PASSWORD_FAILURE, error.message));
                }
            }
        );
    };
}
function logOut() {
    authService.logout();
    return request(authTypes.LOG_OUT);
}
function request(type) {
    return { type: type };
}
function success(type, data) {
    return { type: type, payload: data };
}
function failure(type, error) {
    return { type: type, payload: error ?? "" };
}
