import React, { useEffect, useState } from "react";
import Dropdown from "../Dropdown";
import { authActions, orderActions } from "redux/actions";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "../InputField/TextInput";
import { truncate } from "lodash";
import Button from "../Button";
import { getFromStorage } from "helpers";
import { Link, useLocation, useNavigate } from "react-router-dom";
import TabsContainer from "../TabsContainer";
import { AllRead } from "components/views/Dashboard/Notifications/AllRead";
import { UnRead } from "components/views/Dashboard/Notifications/Unread";
import { toast } from "react-hot-toast";
import VendorAvatar from "../VendorAvatar";

const DashboardNav = ({ notify, setNotify }) => {
    const [profile, setProfile] = useState({});
    const [total, setTotal] = useState("");
    const [unread, setUnread] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [notification, setNotification] = useState({ title: "", body: "" });
    const [userProfile, setUserProfile] = useState(() => getFromStorage("ally-vendor"));
    const { fetchVendorProfileSuccess } = useSelector((state) => state.authVendor);

    const { fetchedVendorNotificationSuccess } = useSelector((state) => state.order);

    const nameArr = profile?.profile?.businessName
        ? profile?.profile?.businessName?.split(" ")
        : [];
    const initials = nameArr
        ?.map((name) => name[0]?.toUpperCase())
        ?.join("")
        ?.slice(0, 3);

    useEffect(() => {
        dispatch(orderActions.getVendorNotification(unread));
    }, [dispatch, unread]);

    useEffect(() => {
        if (Boolean(fetchedVendorNotificationSuccess)) {
            setTotal(fetchedVendorNotificationSuccess?.total);
        }
    }, [fetchedVendorNotificationSuccess]);

    useEffect(() => {
        if (!Boolean(fetchVendorProfileSuccess)) {
            dispatch(authActions.getVendorProfile(userProfile?.token));
        }
    }, [dispatch, fetchVendorProfileSuccess]);

    useEffect(() => {
        if (Boolean(fetchVendorProfileSuccess)) {
            setProfile(fetchVendorProfileSuccess);
        }
    }, [fetchVendorProfileSuccess]);

    const path = location.pathname === `/dashboard/notifications`;

    useEffect(() => {
        if (path) {
            setNotify(false);
        }
    }, [path]);

    const [activeTab, setActiveTab] = useState(0);
    const tabs = ["All", "Unread"];
    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return <AllRead nav />;

            case 1:
                return <UnRead nav />;

            default:
                return;
        }
    };

    const toastNotify = () => toast(<ToastDisplay />);
    function ToastDisplay() {
        return (
            <div>
                <p>
                    <b>{notification?.title}</b>
                </p>
                <p>{notification?.body}</p>
            </div>
        );
    }
    useEffect(() => {
        if (notification?.title) {
            toastNotify();
        }
    }, [notification]);

    return (
        <nav className="w-full h-[84px] flex justify-between px-[47px] items-center bg-white border-b border-slate-100">
            <div className="mr-[56px]">
                <img
                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702538223/Tradeally_2.0/synthAlly_logo_skcywb.svg"
                    alt="synthAlly logo"
                    width={128}
                    height={24}
                />
            </div>

            <div className="w-full flex items-center justify-between pl-[37px]">
                <div className="w-[30%]">
                    {/* <TextInput
                        name={"navSearch"}
                        type={"text"}
                        inputPlaceholder={"Search"}
                        inputClassName={"!h-[36px] pl-[0px] !mb-0 text-sm"}
                        leftIcon={
                            <i className="ri-search-line before:content-['\f0d1] text-slate-400"></i>
                        }
                        inputContainerClassname={
                            "!rounded-[4px] !pl-[40px] !bg-slate-50 border-slate-100 !h-[36px]"
                        }
                    /> */}
                </div>
                <div className="flex items-center gap-6">
                    {/* <Button btnText={"Invite team"} btnClassname="!py-2 !px-4" /> */}
                    <div
                        className="relative cursor-pointer"
                        onClick={() => {
                            if (!path) {
                                setNotify(true);
                            }
                        }}
                    >
                        <i className="ri-notification-3-fill text-xl text-slate-700"></i>
                        {total !== 0 && (
                            <p className="py-[2px] px-[3px] flex items-center justify-center absolute top-0 right-0 bg-r-50 rounded-[1px] text-white font-rocGroteskMedium text-[5px]">
                                {total}
                            </p>
                        )}
                        {notify && (
                            <div
                                id="modal"
                                className={`
                }   w-full absolute h-full !z-[9000]`}
                            >
                                <div className="bg-white mt-3.5 w-[460px] absolute -right-7  border border-slate-100 rounded-[6px] py-6 shadow-md px-6">
                                    <div className="mb-[25px] ">
                                        <div className="flex items-center justify-between">
                                            <h1 className="text-gm-50 font-rocGroteskMedium text-base">
                                                Notifications
                                            </h1>
                                            <div
                                                onClick={() => {
                                                    navigate("/dashboard/notifications");
                                                }}
                                                className="text-slate-500 underline font-rocGroteskMedium text-sm"
                                            >
                                                <p> Show all</p>
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <TabsContainer
                                                tabs={tabs}
                                                activeTab={activeTab}
                                                setActiveTab={setActiveTab}
                                                component={displayActiveTab()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <Dropdown
                        value={""}
                        dropdown={
                            <div className="grid grid-cols-[0.3fr_2fr_0.2fr] items-center gap-2 py-2.5 px-2.5 w-[224px] border rounded">
                                <VendorAvatar
                                    size={24}
                                    imageSrc={profile?.user?.profile?.avatar}
                                    name={profile?.user?.profile?.businessName}
                                />
                                <p className="text-sm capitalize">
                                    {truncate(profile?.user?.profile?.businessName, {
                                        length: 22,
                                    })}
                                </p>
                                <div className="flex flex-col justify-center items-center gap-[1px] mt-[-21px]">
                                    <i className="ri-arrow-drop-up-fill h-[4px] before:content-['\ea55']"></i>
                                    <i className="ri-arrow-drop-down-fill h-[4px] before:content-['\ea4f']"></i>
                                </div>
                            </div>
                        }
                        dropdownContainerClasses={
                            " max-sm:left-[-274px] top-[51px] shadow-cardShadow-2 rounded !border-0"
                        }
                        dropdownClassName={"!max-h-fit"}
                        dropdownItemsClasses={"last:text-r-50 last:hover:bg-r-50/20"}
                        name={"quickAction"}
                        dropdownOptions={[
                            {
                                label: "Logout",
                                value: "logout",
                                icon: <i className="ri-logout-box-line"></i>,
                                action: () => {
                                    dispatch(authActions.logOut());
                                    localStorage.removeItem("redirectUrl");
                                },
                            },
                        ]}
                    />
                </div>
            </div>
        </nav>
    );
};

export default DashboardNav;
