import CustomToast from "components/common/CustomToast";
import { orderTypes } from "redux/types";
import { toast } from "react-hot-toast";
import { orderService } from "services";

export const orderActions = {
    getRfqs,
    getOrders,
    getSingleRfq,
    getSinglePurchaseOrder,
    getQuotes,
    getSingleQuotes,
    deleteQuote,
    updateOrder,
    resetUpdateOrderSuccess,
    resetSendPurchaseOrderSuccess,
    updateRfq,
    resetUpdateRfqSuccess,
    resetCreatePurchaseOrderSuccess,
    resetDeclineRfqSuccess,
    createPurchaseOrder,
    updatePurchaseOrder,
    getPurchaseOrder,
    sendPurchaseOrder,
    getApprovedResponses,
    declineRfqs,
    sendQuote,
    createQuote,
    updateQuote,
    resetCreateQuoteSuccess,
    resetSendQuoteSuccess,
    getVendorNotification,
    readOneVendorNotification,
    resetReadOneNotification,
    resetDeleteQuoteSuccess,
    resetUpdateQuoteSuccess,
};

function getRfqs(rfqLimit, search, vendor, orderId, amount) {
    return (dispatch) => {
        dispatch(request(orderTypes.FETCH_RFQS_REQUEST));

        orderService.getRfqs(rfqLimit, search, vendor, orderId, amount).then(
            (res) => {
                dispatch(success(orderTypes.FETCH_RFQS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(orderTypes.FETCH_RFQS_FAILURE, error?.message));
                }
            }
        );
    };
}

function getVendorNotification(unread) {
    return (dispatch) => {
        dispatch(request(orderTypes.FETCH_VENDOR_NOTIFICATION_REQUEST));

        orderService.getVendorNotification(unread).then(
            (res) => {
                dispatch(success(orderTypes.FETCH_VENDOR_NOTIFICATION_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(orderTypes.FETCH_VENDOR_NOTIFICATION_FAILURE, error?.message));
                }
            }
        );
    };
}

function readOneVendorNotification(notificationId) {
    return (dispatch) => {
        dispatch(request(orderTypes.READ_ONE_VENDOR_NOTIFICATION_REQUEST));

        orderService.readOneVendorNotification(notificationId).then(
            (res) => {
                dispatch(success(orderTypes.READ_ONE_VENDOR_NOTIFICATION_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(orderTypes.READ_ONE_VENDOR_NOTIFICATION_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function getOrders(orderLimit) {
    return (dispatch) => {
        dispatch(request(orderTypes.FETCH_ORDERS_REQUEST));

        orderService.getOrders(orderLimit).then(
            (res) => {
                dispatch(success(orderTypes.FETCH_ORDERS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(orderTypes.FETCH_ORDERS_FAILURE, error?.message));
                }
            }
        );
    };
}

function getSingleRfq(rfqId) {
    return (dispatch) => {
        dispatch(request(orderTypes.FETCH_SINGLE_RFQ_REQUEST));

        orderService.getSingleRfq(rfqId).then(
            (res) => {
                dispatch(success(orderTypes.FETCH_SINGLE_RFQ_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(orderTypes.FETCH_SINGLE_RFQ_FAILURE, error?.message));
                }
            }
        );
    };
}

function getSinglePurchaseOrder(poId) {
    return (dispatch) => {
        dispatch(request(orderTypes.FETCH_SINGLE_PURCHASE_ORDER_REQUEST));

        orderService.getSinglePurchaseOrder(poId).then(
            (res) => {
                dispatch(success(orderTypes.FETCH_SINGLE_PURCHASE_ORDER_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(orderTypes.FETCH_SINGLE_PURCHASE_ORDER_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function getQuotes(quoteLimit, search, vendor, quoteId, amount) {
    return (dispatch) => {
        dispatch(request(orderTypes.FETCH_QUOTES_REQUEST));

        orderService.getQuotes(quoteLimit, search, vendor, quoteId, amount).then(
            (res) => {
                dispatch(success(orderTypes.FETCH_QUOTES_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(orderTypes.FETCH_QUOTES_FAILURE, error.message));
                }
            }
        );
    };
}

function updateOrder(orderId, data) {
    return (dispatch) => {
        dispatch(request(orderTypes.UPDATE_ORDER_REQUEST));

        orderService.updateOrder(orderId, data).then(
            (res) => {
                dispatch(success(orderTypes.UPDATE_ORDER_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Updated successfully"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(orderTypes.UPDATE_ORDER_FAILURE, error?.message));
                }
            }
        );
    };
}

function getSingleQuotes(quoteId) {
    return (dispatch) => {
        dispatch(request(orderTypes.FETCH_SINGLE_QUOTES_REQUEST));

        orderService.getSingleQuotes(quoteId).then(
            (res) => {
                dispatch(success(orderTypes.FETCH_SINGLE_QUOTES_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(orderTypes.FETCH_SINGLE_QUOTES_FAILURE, error.message));
                }
            }
        );
    };
}
function updateRfq(rfqId, data) {
    return (dispatch) => {
        dispatch(request(orderTypes.UPDATE_RFQ_REQUEST));

        orderService.updateRfq(rfqId, data).then(
            (res) => {
                dispatch(success(orderTypes.UPDATE_RFQ_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Updated successfully"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(orderTypes.UPDATE_RFQ_FAILURE, error?.message));
                }
            }
        );
    };
}

function getApprovedResponses(limit, search) {
    return (dispatch) => {
        dispatch(request(orderTypes.FETCH_APPROVED_RESPONSE_REQUEST));

        orderService.getApprovedResponses(limit, search).then(
            (res) => {
                dispatch(success(orderTypes.FETCH_APPROVED_RESPONSE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(orderTypes.FETCH_APPROVED_RESPONSE_FAILURE, error.message));
                }
            }
        );
    };
}

function deleteQuote(reqObj) {
    return (dispatch) => {
        dispatch(request(orderTypes.DELETE_QUOTE_REQUEST));

        orderService.deleteQuote(reqObj).then(
            (res) => {
                dispatch(success(orderTypes.DELETE_QUOTE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(orderTypes.DELETE_QUOTE_FAILURE, error.message));
                }
            }
        );
    };
}

function createPurchaseOrder(reqObj) {
    return (dispatch) => {
        dispatch(request(orderTypes.CREATE_PURCHASE_ORDER_REQUEST));

        orderService.createPurchaseOrder(reqObj).then(
            (res) => {
                dispatch(success(orderTypes.CREATE_PURCHASE_ORDER_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(orderTypes.CREATE_PURCHASE_ORDER_FAILURE, error?.message));
                }
            }
        );
    };
}

function sendQuote(reqObj) {
    return (dispatch) => {
        dispatch(request(orderTypes.SEND_QUOTE_REQUEST));

        orderService.sendQuote(reqObj).then(
            (res) => {
                dispatch(success(orderTypes.SEND_QUOTE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(orderTypes.SEND_QUOTE_FAILURE, error?.message));
                }
            }
        );
    };
}

function createQuote(reqObj) {
    return (dispatch) => {
        dispatch(request(orderTypes.CREATE_QUOTE_REQUEST));

        orderService.createQuote(reqObj).then(
            (res) => {
                dispatch(success(orderTypes.CREATE_QUOTE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(orderTypes.CREATE_QUOTE_FAILURE, error?.message));
                }
            }
        );
    };
}

function updateQuote(reqObj, quoteId) {
    return (dispatch) => {
        dispatch(request(orderTypes.UPDATE_QUOTE_REQUEST));

        orderService.updateQuote(reqObj, quoteId).then(
            (res) => {
                dispatch(success(orderTypes.UPDATE_QUOTE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(orderTypes.UPDATE_QUOTE_FAILURE, error?.message));
                }
            }
        );
    };
}

function declineRfqs(data, rfqId) {
    return (dispatch) => {
        dispatch(request(orderTypes.DECLINE_RFQS_REQUEST));

        orderService.declineRfqs(data, rfqId).then(
            (res) => {
                dispatch(success(orderTypes.DECLINE_RFQS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(orderTypes.DECLINE_RFQS_FAILURE, error?.message));
                }
            }
        );
    };
}

function updatePurchaseOrder(poId, data) {
    return (dispatch) => {
        dispatch(request(orderTypes.UPDATE_PURCHASE_ORDER_REQUEST));

        orderService.updatePurchaseOrder(poId, data).then(
            (res) => {
                dispatch(success(orderTypes.UPDATE_PURCHASE_ORDER_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Updated successfully"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(orderTypes.UPDATE_PURCHASE_ORDER_FAILURE, error?.message));
                }
            }
        );
    };
}

function getPurchaseOrder(taId, search, poLimit) {
    return (dispatch) => {
        dispatch(request(orderTypes.FETCH_PURCHASE_ORDER_REQUEST));

        orderService.getPurchaseOrder(taId, search, poLimit).then(
            (res) => {
                dispatch(success(orderTypes.FETCH_PURCHASE_ORDER_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(orderTypes.FETCH_PURCHASE_ORDER_FAILURE, error.message));
                }
            }
        );
    };
}

function sendPurchaseOrder(taId, body) {
    return (dispatch) => {
        dispatch(request(orderTypes.SEND_PURCHASE_ORDER_REQUEST));

        orderService.sendPurchaseOrder(taId, body).then(
            (res) => {
                dispatch(success(orderTypes.SEND_PURCHASE_ORDER_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(orderTypes.SEND_PURCHASE_ORDER_FAILURE, error.message));
                }
            }
        );
    };
}

function resetUpdateOrderSuccess() {
    return (dispatch) => {
        dispatch(success(orderTypes.UPDATE_ORDER_SUCCESS, null));
    };
}

function resetCreatePurchaseOrderSuccess() {
    return (dispatch) => {
        dispatch(success(orderTypes.CREATE_PURCHASE_ORDER_SUCCESS, null));
    };
}

function resetCreateQuoteSuccess() {
    return (dispatch) => {
        dispatch(success(orderTypes.CREATE_QUOTE_SUCCESS, null));
    };
}

function resetUpdateQuoteSuccess() {
    return (dispatch) => {
        dispatch(success(orderTypes.UPDATE_QUOTE_SUCCESS, null));
    };
}

function resetSendPurchaseOrderSuccess() {
    return (dispatch) => {
        dispatch(success(orderTypes.SEND_PURCHASE_ORDER_SUCCESS, null));
    };
}
function resetSendQuoteSuccess() {
    return (dispatch) => {
        dispatch(success(orderTypes.SEND_QUOTE_SUCCESS, null));
    };
}
function resetUpdateRfqSuccess() {
    return (dispatch) => {
        dispatch(success(orderTypes.UPDATE_RFQ_SUCCESS, null));
    };
}
function resetReadOneNotification() {
    return (dispatch) => {
        dispatch(success(orderTypes.READ_ONE_VENDOR_NOTIFICATION_REQUEST, null));
    };
}
function resetDeleteQuoteSuccess() {
    return (dispatch) => {
        dispatch(success(orderTypes.DELETE_QUOTE_SUCCESS, null));
    };
}

function resetDeclineRfqSuccess() {
    return (dispatch) => {
        dispatch(success(orderTypes.DECLINE_RFQS_SUCCESS, null));
    };
}

function request(type) {
    return { type: type };
}
function success(type, data) {
    return { type: type, payload: data };
}
function failure(type, error) {
    return { type: type, payload: error ?? "" };
}
