import Loader from "components/common/Loader";
import { onScroll } from "helpers";
import { truncate } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, } from "react-router-dom";
import { orderActions } from "redux/actions";

export const AllRead = ({ nav }) => {
  const limit = 20;
 
  const [notifyLimit, setNotifyLimit] = useState(limit);
  const [notification, setNotification] = useState([]);
  const [loadMore, setLoadMore] = useState(false);
  const [notifyPagination, setNotifyPagination] = useState({
    current: "",
    number_of_pages: "",
  });
  const { fetchingVendorNotification, fetchedVendorNotificationSuccess } =
    useSelector((state) => state.order);
  const dashboardContent = document.getElementById("dashboardContent");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onNotification = useCallback(
    () =>
      onScroll(
        dashboardContent,
        notifyPagination?.current,
        notifyPagination?.number_of_pages,
        () => {
          setNotifyLimit(() => notifyLimit + limit);
          setLoadMore(true);
        }
      ),
    [dashboardContent, notifyPagination]
  );
  useEffect(() => {
    dispatch(orderActions.getVendorNotification());
  }, [dispatch]);

  useEffect(() => {
    dashboardContent?.addEventListener("scroll", onNotification);
    return () => {
      dashboardContent?.removeEventListener("scroll", onNotification);
    };
  }, [dashboardContent, onNotification]);

  useEffect(() => {
    if (Boolean(fetchedVendorNotificationSuccess)) {
      setNotification(fetchedVendorNotificationSuccess?.notifications);
      setNotifyPagination({
        current: fetchedVendorNotificationSuccess?.pagination?.current,
        number_of_pages:
          fetchedVendorNotificationSuccess?.pagination?.number_of_pages,
      });
    }
  }, [fetchedVendorNotificationSuccess]);
  const limitedNotification =
    nav && notification.length > 4 ? notification.slice(0, 4) : notification;


  return (
    <div>
      {limitedNotification?.map((n) => (
        <div
          key={n?._id}
          className="py-5 border-b cursor-pointer border-b-slate-100 flex items-center space-x-4"
          onClick={() => {
            orderActions.readOneVendorNotification(n?._id);
            navigate(
              `/dashboard/${n?.notificationType.toLowerCase()}/${n?.dataId}`
            );
            window.location.reload();
          }}
        >
          <div className="w-8 h-8">
            <img src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1694624630/Tradeally_2.0/undefined_-_Imgur_1_prpxel.jpg" />
          </div>
          <div className="font-rocGroteskMedium space-y-0.5">
            <h2 className="text-slate-950 text-sm ">{n?.title}</h2>
            <p className="text-slate-800 text-xs ">
              {truncate(n?.body, { length: `${nav ? 68 : 280}` })}
            </p>
            <p className="text-slate-500 text-xs">
              {moment(n?.createdAt).format("DD MMM YYYY | LT")}
            </p>
          </div>
        </div>
      ))}

      {fetchingVendorNotification && loadMore && (
        <div className="flex my-10 justify-center">
          <Loader color="gm-25" size={4} />
        </div>
      )}
      {notifyPagination?.current === notifyPagination?.number_of_pages &&
        !nav && (
          <div className="flex my-4 justify-center text-sm font-rocGroteskMedium">
            End of list
          </div>
        )}
    </div>
  );
};
