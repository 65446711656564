import { PrivateRoutes } from "components/common/Routes/PrivateRoute";
import DashboardFrame from "components/layout/Vendor/DashboardFrame";
import React from "react";
import { Outlet } from "react-router-dom";

const Dashboard = ({ dashboardClassName }) => {
  return (
    <PrivateRoutes>
      <DashboardFrame>
        <Outlet />
      </DashboardFrame>
    </PrivateRoutes>
  );
};

export default Dashboard;
