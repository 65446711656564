import { shipmentTypes } from "redux/types";

const initialState = {
    creatingShipment: false,
    createShipmentSuccess: null,
    createShipmentFailure: null,
    updatingShipment: false,
    updateShipmentSuccess: null,
    updateShipmentFailure: null,
    fetchingShipments: false,
    fetchShipmentSuccess: null,
    fetchShipmentFailure: null,
    fetchingSingleShipment: false,
    fetchSingleShipmentSuccess: null,
    fetchSingleShipmentFailure: null,
    fetchingPorts: false,
    fetchPortsSuccess: null,
    fetchSPortsFailure: null,
    updatingShipmentDocument: false,
    updateShipmentDocumentSuccess: null,
    updateShipmentDocumentFailure: null,
    updatingBOL: false,
    updateBOLSuccess: null,
    updateBOLFailure: null,
    sendingStatusUpdate: false,
    sendStatusUpdateSuccess: null,
    sendStatusUpdateFailure: null,
};

export const shipment = (state = initialState, action) => {
    switch (action.type) {
        case shipmentTypes.CREATE_SHIPMENT_REQUEST:
            return {
                ...state,
                creatingShipment: true,
                createShipmentSuccess: null,
                createShipmentFailure: null,
            };
        case shipmentTypes.CREATE_SHIPMENT_SUCCESS:
            return {
                ...state,
                creatingShipment: false,
                createShipmentSuccess: action.payload,
                createShipmentFailure: null,
            };
        case shipmentTypes.CREATE_SHIPMENT_FAILURE:
            return {
                ...state,
                creatingShipment: false,
                createShipmentSuccess: null,
                createShipmentFailure: action.payload,
            };

        case shipmentTypes.UPDATE_SHIPMENT_REQUEST:
            return {
                ...state,
                updatingShipment: true,
                updateShipmentSuccess: null,
                updateShipmentFailure: null,
            };
        case shipmentTypes.UPDATE_SHIPMENT_SUCCESS:
            return {
                ...state,
                updatingShipment: false,
                updateShipmentSuccess: action.payload,
                updateShipmentFailure: null,
            };
        case shipmentTypes.UPDATE_SHIPMENT_FAILURE:
            return {
                ...state,
                updatingShipment: false,
                updateShipmentSuccess: null,
                updateShipmentFailure: action.payload,
            };

        case shipmentTypes.FETCH_SHIPMENTS_REQUEST:
            return {
                ...state,
                fetchingShipments: true,
                fetchShipmentSuccess: null,
                fetchShipmentFailure: null,
            };
        case shipmentTypes.FETCH_SHIPMENTS_SUCCESS:
            return {
                ...state,
                fetchingShipments: false,
                fetchShipmentSuccess: action.payload,
                fetchShipmentFailure: null,
            };
        case shipmentTypes.FETCH_SHIPMENTS_FAILURE:
            return {
                ...state,
                fetchingShipments: false,
                fetchShipmentSuccess: null,
                fetchShipmentFailure: action.payload,
            };

        case shipmentTypes.FETCH_SINGLE_SHIPMENT_REQUEST:
            return {
                ...state,
                fetchingSingleShipment: true,
                fetchSingleShipmentSuccess: null,
                fetchSingleShipmentFailure: null,
            };
        case shipmentTypes.FETCH_SINGLE_SHIPMENT_SUCCESS:
            return {
                ...state,
                fetchingSingleShipment: false,
                fetchSingleShipmentSuccess: action.payload,
                fetchSingleShipmentFailure: null,
            };
        case shipmentTypes.FETCH_SINGLE_SHIPMENT_FAILURE:
            return {
                ...state,
                fetchingSingleShipment: false,
                fetchSingleShipmentSuccess: null,
                fetchSingleShipmentFailure: action.payload,
            };

        case shipmentTypes.FETCH_PORTS_REQUEST:
            return {
                ...state,
                fetchingPorts: true,
                fetchPortsSuccess: null,
                fetchSPortsFailure: null,
            };
        case shipmentTypes.FETCH_PORTS_SUCCESS:
            return {
                ...state,
                fetchingPorts: false,
                fetchPortsSuccess: action.payload,
                fetchSPortsFailure: null,
            };
        case shipmentTypes.FETCH_PORTS_FAILURE:
            return {
                ...state,
                fetchingPorts: false,
                fetchPortsSuccess: null,
                fetchSPortsFailure: action.payload,
            };

        case shipmentTypes.UPDATE_SHIPMENT_DOCUMENT_REQUEST:
            return {
                ...state,
                updatingShipmentDocument: true,
                updateShipmentDocumentSuccess: null,
                updateShipmentDocumentFailure: null,
            };
        case shipmentTypes.UPDATE_SHIPMENT_DOCUMENT_SUCCESS:
            return {
                ...state,
                updatingShipmentDocument: false,
                updateShipmentDocumentSuccess: action.payload,
                updateShipmentDocumentFailure: null,
            };
        case shipmentTypes.UPDATE_SHIPMENT_DOCUMENT_FAILURE:
            return {
                ...state,
                updatingShipmentDocument: false,
                updateShipmentDocumentSuccess: null,
                updateShipmentDocumentFailure: action.payload,
            };

        case shipmentTypes.UPDATE_BOL_REQUEST:
            return {
                ...state,
                updatingBOL: true,
                updateBOLSuccess: null,
                updateBOLFailure: null,
            };
        case shipmentTypes.UPDATE_BOL_SUCCESS:
            return {
                ...state,
                updatingBOL: false,
                updateBOLSuccess: action.payload,
                updateBOLFailure: null,
            };
        case shipmentTypes.UPDATE_BOL_FAILURE:
            return {
                ...state,
                updatingBOL: false,
                updateBOLSuccess: null,
                updateBOLFailure: action.payload,
            };

        case shipmentTypes.SEND_STATUS_UPDATE_REQUEST:
            return {
                ...state,
                sendingStatusUpdate: true,
                sendStatusUpdateSuccess: null,
                sendStatusUpdateFailure: null,
            };
        case shipmentTypes.SEND_STATUS_UPDATE_SUCCESS:
            return {
                ...state,
                sendingStatusUpdate: false,
                sendStatusUpdateSuccess: action.payload,
                sendStatusUpdateFailure: null,
            };
        case shipmentTypes.SEND_STATUS_UPDATE_FAILURE:
            return {
                ...state,
                sendingStatusUpdate: false,
                sendStatusUpdateSuccess: null,
                sendStatusUpdateFailure: action.payload,
            };

        default:
            return state;
    }
};
