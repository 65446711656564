import { isEqual } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { sidebarLinks } from "variables/sidebarLinks";

const DashboardSidebar = ({ sidebarCollapsed, setSidebarCollapsed }) => {
    const [isHoveredId, setIsHoveredId] = useState(-1);
    const [activeSideLink, setActiveSideLink] = useState("");
    const [activeSidebarId, setActiveSidebarId] = useState(-1);
    const location = useLocation();

    const LinkItem = ({ item, idx }) => {
        const openChildren =
            typeof item?.link === "string"
                ? item?.link.includes(activeSideLink) ||
                  isEqual(item?.link, activeSideLink) ||
                  activeSideLink?.includes(item?.title)
                : item?.link
                      ?.map((path) => activeSideLink?.startsWith?.(path))
                      ?.find((value) => Boolean(value));
        const isSelected =
            typeof item?.link === "string" && typeof activeSideLink === "string"
                ? activeSideLink?.toLowerCase()?.includes(item?.title?.toLowerCase())
                : activeSideLink?.includes(item?.title?.toLowerCase());

        return !item?.children ? (
            <NavLink
                to={item.link}
                onClick={() => {
                    setActiveSideLink(item.link);
                    setActiveSidebarId(idx);
                }}
                end
                onMouseEnter={() => setIsHoveredId(idx)}
                onMouseLeave={() => setIsHoveredId(-1)}
                className={({ isActive }) =>
                    `flex ${sidebarCollapsed ? "px-1" : "px-3"} py-2.5 hover:bg-slate-50 ${
                        isActive && "bg-slate-50"
                    } ${isSelected && "bg-slate-50"} cursor-pointer`
                }
            >
                {({ isActive }) => (
                    <div className="flex gap-2 items-center">
                        {item?.icons && (
                            <i
                                className={`${item.icons} ${
                                    isHoveredId === idx ? "text-g-60" : "text-slate-500"
                                } ${isActive && "!text-g-60"} ${isSelected && "!text-g-60"}`}
                            ></i>
                        )}
                        {!sidebarCollapsed && (
                            <p
                                className={`text-sm ${
                                    sidebarCollapsed ? "opacity-0" : "opacity-1"
                                } ${isHoveredId === idx ? "text-g-60" : "text-slate-500"} ${
                                    isActive && "!text-g-60"
                                } ${isSelected && "!text-g-60"} font-rocGroteskMedium`}
                            >
                                {item.title}
                            </p>
                        )}
                    </div>
                )}
            </NavLink>
        ) : (
            <div>
                <div
                    onClick={() => {
                        setActiveSideLink(item.link);
                        setActiveSidebarId(idx);
                        setSidebarCollapsed(false);
                    }}
                    onMouseEnter={() => setIsHoveredId(idx)}
                    onMouseLeave={() => setIsHoveredId(-1)}
                    className={`flex gap-2 items-center ${
                        sidebarCollapsed ? "px-1" : "px-3"
                    } py-2.5 hover:bg-slate-50 ${
                        (item?.link.includes(activeSideLink) || openChildren) && "bg-slate-50"
                    } cursor-pointer`}
                >
                    {item?.icons && (
                        <i
                            className={`${item.icons} ${
                                isHoveredId === idx ? "text-g-60" : "text-slate-500"
                            } ${
                                (item?.link.includes(activeSideLink) || openChildren) &&
                                "!text-g-60"
                            }`}
                        ></i>
                    )}
                    {!sidebarCollapsed && (
                        <p
                            className={`text-sm ${sidebarCollapsed ? "opacity-0" : "opacity-1"} ${
                                isHoveredId === idx ? "text-g-60" : "text-slate-500"
                            } ${
                                (item?.link.includes(activeSideLink) || openChildren) &&
                                "!text-g-60"
                            } font-rocGroteskMedium`}
                        >
                            {item.title}
                        </p>
                    )}
                </div>
                {(item?.link.includes(activeSideLink) ||
                    isEqual(item?.link, activeSideLink) ||
                    openChildren) &&
                    !sidebarCollapsed && (
                        <div className="pl-4 mt-1">
                            {item?.children?.map((child, index) => {
                                return (
                                    <LinkItem
                                        key={child?.title + index}
                                        item={child}
                                        idx={idx + "-" + index}
                                    />
                                );
                            })}
                        </div>
                    )}
            </div>
        );
    };

    useEffect(() => {
        const pathArr = location.pathname
            ?.split("/")
            ?.filter((str) => str !== "")
            ?.slice(1);

        if (pathArr?.length > 0) {
            setActiveSideLink("/" + pathArr?.join("/"));
        } else {
            setActiveSidebarId(0);
        }
    }, [location.pathname]);

    return (
        <div
            className={`${
                sidebarCollapsed ? "w-[58px]" : "w-full "
            }  bg-white py-6 px-4 cursor-pointer relative flex flex-col justify-between gap-y-1 border-r border-slate-100 h-full`}
        >
            <div
                onClick={() => setSidebarCollapsed((prev) => !prev)}
                className={`flex ${
                    sidebarCollapsed && "rotate-180"
                } z-30 items-center absolute bg-white right-[-14px] top-[14px] cursor-pointer justify-center w-[28px] h-[28px] px-1.5 rounded-full border border-n-20 shadow-boxShadow`}
            >
                <i className="ri-arrow-left-s-line text-slate-400"></i>
            </div>
            <div className="flex flex-col gap-y-1">
                {sidebarLinks?.map((item, idx) => {
                    return <LinkItem key={item.title + idx} item={item} idx={idx} />;
                })}
            </div>
        </div>
    );
};

export default DashboardSidebar;
