import { useEffect, useState } from "react";
import { getFromStorage, saveToStorage } from "helpers";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import { authService } from "services";
import { authActions } from "redux/actions";
import { useDispatch } from "react-redux";
import { parseJwt } from "helpers/decodeTokent";

export const PrivateRoutes = ({ children }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [paramsData] = useSearchParams();
    const userToken = paramsData?.get("token");

    const [profile, setProfile] = useState(() => getFromStorage("ally-vendor"));

    const handleStorageChange = () => {
        setProfile(getFromStorage("ally-vendor"));
    };
    useEffect(() => {
        window.addEventListener("storage", handleStorageChange);

        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    useEffect(() => {
        if (Boolean(userToken)) {
            const tokenData = parseJwt(userToken);
            saveToStorage("ally-vendor", { token: userToken, user: { active: tokenData?.active } });
        }
    }, [userToken]);

    if (!authService.loggedIn() && !Boolean(userToken)) {
        if (!authService.loggedIn() && Boolean(profile?.user)) {
            dispatch(authActions.logOut());
        }
        localStorage.setItem("redirectUrl", location.pathname);

        window.open(`/login`, "_self");
        return;
    }

    if (
        !profile?.user?.active &&
        authService.loggedIn() &&
        profile?.token &&
        !profile?.verifyComplete &&
        location.pathname !== `/registration-success/${profile?.token}` &&
        !Boolean(userToken)
    ) {
        console.log("this is private registration block");
        return <Navigate to={`/registration-success/${profile?.token}?resend=true`} replace />;
    }

    return children;
};
