import React from "react";

const VendorAvatar = ({
    size,
    containerClassname,
    textClassname,
    name,
    imageSrc,
}) => {
    const nameArr = name ? name?.split(" ") : [];
    const initials = nameArr
        ?.map((user) => user[0]?.toUpperCase())
        ?.join("")
        ?.slice(0, 3);

    return imageSrc ? (
        <img
            src={imageSrc}
            width={size}
            height={size}
            alt="logo"
            className={`rounded-full w-[${size}px] h-[${size}px]`}
            style={{ width: `${size}px`, height: `${size}px` }}
        />
    ) : (
        <div
            className={`w-[${size}px] h-[${size}px] rounded-full bg-p-55 flex items-center justify-center ${containerClassname}`}
            style={{ width: `${size}px`, height: `${size}px` }}
        >
            <p className={`text-xs uppercase text-white font-rocGroteskBold ${textClassname}`}>
                {initials || "V"}
            </p>
        </div>
    );
};

export default VendorAvatar;
