import DashboardSidebar from "components/common/DashboardSidebar";
import Loader from "components/common/Loader";
import DashboardNav from "components/common/Navbar/DashboardNav";
import React, { Suspense, useState } from "react";

const DashboardFrame = ({ children }) => {
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const [notify, setNotify] = useState(false);

    return (
        <div className="bg-body-1 w-full min-h-screen overflow-x-hidden">
            <DashboardNav notify={notify} setNotify={setNotify} />
            <div
                onClick={() => setNotify(false)}
                className={`grid ${
                    sidebarCollapsed ? "grid-cols-[4%_auto]" : "grid-cols-[17%_auto]"
                } h-[calc(100vh-84px)]`}
            >
                <DashboardSidebar
                    setSidebarCollapsed={setSidebarCollapsed}
                    sidebarCollapsed={sidebarCollapsed}
                />
                <div id="dashboardContent" className="px-4 pt-6 pb-6 w-full overflow-y-scroll">
                    <Suspense
                        fallback={
                            <div className="h-full flex items-center justify-center">
                                <Loader color={"gm-50"} size={8} />
                            </div>
                        }
                    >
                        {children}
                    </Suspense>
                </div>
            </div>
        </div>
    );
};

export default DashboardFrame;
